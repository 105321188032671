import { ChangeDetectionStrategy, Component } from '@angular/core';
import { localizationKeys } from 'src/app/localization/localization-keys';

@Component({
  selector: 'app-wait-for-sync-dialog',
  templateUrl: './wait-for-sync-dialog.component.html',
  styleUrls: ['./wait-for-sync-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaitForSyncDialogComponent {
  readonly localizationKeys = localizationKeys;
}
