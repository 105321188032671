<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="navbar-brand">
    <img class="logo" src="assets/siemens-logo.svg" alt="Siemens logo">
    <span class="ml-2">eTest Online</span>
  </div>
  <button class="navbar-toggler" type="button" (click)="show = !show">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [class.show]="show">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item">
        <button mat-button (click)="onToggleFullscreen()">{{ localizationKeys.fullscreen | localize }}</button>
      </li>

      <li class="nav-item">
        <button mat-button [matMenuTriggerFor]="languageMenu">Language</button>

        <mat-menu #languageMenu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="uiLanguageMenu">UI language</button>
          <button mat-menu-item [disabled]="testLanguages == undefined" [matMenuTriggerFor]="testLanguageMenu">Test language</button>
          <button mat-menu-item [disabled]="termsLanguages == undefined" [matMenuTriggerFor]="termsLanguageMenu">Terms language</button>
          <button mat-menu-item [disabled]="feedbackLanguages == undefined" [matMenuTriggerFor]="feedbackLanguageMenu">Feedback language</button>
        </mat-menu>

        <mat-menu #uiLanguageMenu="matMenu">
          <button mat-menu-item *ngFor="let language of uiLanguages" (click)="setUiLanguage(language)">
            {{ language.name }}
          </button>
        </mat-menu>

        <mat-menu #termsLanguageMenu="matMenu">
          <ng-container *ngIf="termsLanguages != undefined">
            <button mat-menu-item *ngFor="let language of termsLanguages" (click)="setTermsLanguage(language)">
              {{ language.name }}
            </button>
          </ng-container>
        </mat-menu>

        <mat-menu #testLanguageMenu="matMenu">
          <ng-container *ngIf="testLanguages != undefined">
            <button mat-menu-item *ngFor="let language of testLanguages" (click)="setTestLanguage(language)">
              {{ language.name }}
            </button>
          </ng-container>
        </mat-menu>

        <mat-menu #feedbackLanguageMenu="matMenu">
          <ng-container *ngIf="feedbackLanguages != undefined">
            <button mat-menu-item *ngFor="let language of feedbackLanguages" (click)="setFeedbackLanguage(language)">
              {{ language.name }}
            </button>
          </ng-container>
        </mat-menu>
      </li>
    </ul>

    <button app-button *ngIf="isLoggedIn" [color]="'success'" [outline]="true" class="my-2 my-sm-0" (click)="onLogout()">{{ localizationKeys.logout | localize }}</button>
  </div>
</nav>
