import { NewEntity } from '@tstdl/base/database';
import { Entity } from './entity';

export type WorkshopState = Entity & {
  workshopId: string,
  tag: string,
  released: boolean,
  broadcastMessage: { tag: string, text: string } | null
};

export type NewWorkshopState = NewEntity<WorkshopState>;
