import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClozeQuestionRecord, Language, MultipleChoiceQuestionRecord, QuestionRecord } from 'src/app/common/model';
import { ClozeQuestionWithReference, MultipleChoiceQuestionWithReference, QuestionType, QuestionWithReference } from 'src/app/common/model/question';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionComponent {
  @Input() question: QuestionWithReference;
  @Input() record: QuestionRecord;
  @Input() language: Language | undefined;
  @Input() readonly: boolean;

  @Output() readonly recordChange: EventEmitter<QuestionRecord>;

  get questionType(): string {
    switch (this.question.type) {
      case QuestionType.MultipleChoice:
        return 'multiple-choice';

      case QuestionType.Cloze:
        return 'cloze';

      default:
        throw new Error('type not supported');
    }
  }

  get multipleChoiceQuestionWithReference(): MultipleChoiceQuestionWithReference {
    return this.question as MultipleChoiceQuestionWithReference;
  }

  get clozeQuestionWithReference(): ClozeQuestionWithReference {
    return this.question as ClozeQuestionWithReference;
  }

  get multipleChoiceQuestionRecord(): MultipleChoiceQuestionRecord {
    return this.record as MultipleChoiceQuestionRecord;
  }

  get clozeQuestionRecord(): ClozeQuestionRecord {
    return this.record as ClozeQuestionRecord;
  }

  constructor() {
    this.readonly = false;
    this.recordChange = new EventEmitter();
  }

  onRecordChange(record: QuestionRecord): void {
    this.recordChange.emit(record);
  }
}
