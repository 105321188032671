import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import { timeout } from '@tstdl/base/utils';

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSelectorComponent implements OnInit, ControlValueAccessor {
  private readonly changeDetectorRef: ChangeDetectorRef;
  private readonly control: NgControl;

  private fileInput: HTMLInputElement;
  private fileNameInput: HTMLInputElement;
  private _onChange: (value: any) => void;
  private _onTouched: () => void;

  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() multiple: boolean;
  @Input() accept: undefined | string;

  @Output() readonly disabledChange: EventEmitter<boolean>;
  @Output() readonly filesSelected: EventEmitter<File[] | undefined>;

  @ViewChild('fileInput', { static: true }) fileInputElementRef: ElementRef<HTMLInputElement>;
  @ViewChild('fileNameInput', { static: true }) fileNameInputElementRef: ElementRef<HTMLInputElement>;

  files: undefined | File[];
  fileName: string;

  get isValid(): boolean {
    return this.control.valid as boolean;
  }

  get isInvalid(): boolean {
    return this.control.invalid as boolean;
  }

  get selectedFileName(): string {
    const files = this.fileInput.files;

    if (files == undefined || files.length == 0) {
      return '';
    }

    if (files.length == 1) {
      return (files.item(0) as File).name;
    }

    return `${files.length} files selected`;
  }

  constructor(changeDetectorRef: ChangeDetectorRef, control: NgControl) {
    this.changeDetectorRef = changeDetectorRef;
    this.control = control;

    this.filesSelected = new EventEmitter();
    this.disabledChange = new EventEmitter();

    this._onChange = () => { /* ignore if not set by reactive forms */ };
    this._onTouched = () => { /* ignore if not set by reactive forms */ };

    this.disabled = false;
    this.placeholder = '';
    this.multiple = false;
    this.accept = undefined;

    control.valueAccessor = this;
  }

  ngOnInit(): void {
    this.fileInput = this.fileInputElementRef.nativeElement;
    this.fileNameInput = this.fileNameInputElementRef.nativeElement;

    if (this.control.control == undefined) {
      throw new Error('control not set');
    }

    this.control.control.setValidators(() => this.validate());
  }

  select(): void {
    this.fileInput.click();
    this._onTouched();
  }

  fileInputChange(): void {
    this.files = (this.fileInput.files == undefined) ? undefined : Array.from(this.fileInput.files);
    this.filesSelected.emit(this.files);
    this._onChange(this.files);
    this.fileNameInput.focus();
    this.changeDetectorRef.markForCheck();

    timeout(100).then(() => this.fileNameInput.blur());
  }

  writeValue(files: File[]): void {
    this.files = files;
    this.filesSelected.next(files);
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.disabledChange.emit(disabled);
    this.changeDetectorRef.markForCheck();
  }

  validate(): ValidationErrors | null {
    const files = this.fileInput.files;

    const valid = files != undefined
      && (
        (!this.multiple && files.length == 1) ||
        (this.multiple && files.length > 0)
      );

    if (!valid) {
      return {
        invalid: true
      };
    }

    return null;
  }
}
