import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkshopStateLoadByWorkshopParameters, WorkshopStateLoadByWorkshopResult, workshopStateLoadByWorkshopUrl, WorkshopStateLockParameters, WorkshopStateLockResult, workshopStateLockUrl, WorkshopStateReleaseParameters, WorkshopStateReleaseResult, workshopStateReleaseUrl, WorkshopStateSetBroadcastMessageBody, WorkshopStateSetBroadcastMessageResult, workshopStateSetBroadcastMessageUrl } from 'src/app/common/api/workshop-state';
import { WorkshopState } from 'src/app/common/model/workshop-state';
import { ApiService, CacheType } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class WorkshopStateService {
  private readonly apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  loadByWorkshop({ workshopId }: WorkshopStateLoadByWorkshopParameters): Observable<WorkshopState> {
    return this.apiService.get<WorkshopStateLoadByWorkshopParameters, WorkshopStateLoadByWorkshopResult>(workshopStateLoadByWorkshopUrl, { workshopId }, CacheType.Fallback);
  }

  release({ workshopId, trainerId }: WorkshopStateReleaseParameters): Observable<WorkshopStateReleaseResult> {
    return this.apiService.get<WorkshopStateReleaseParameters, WorkshopStateReleaseResult>(workshopStateReleaseUrl, { workshopId, trainerId });
  }

  lock({ workshopId, trainerId }: WorkshopStateLockParameters): Observable<WorkshopStateLockResult> {
    return this.apiService.get<WorkshopStateLockParameters, WorkshopStateLockResult>(workshopStateLockUrl, { workshopId, trainerId });
  }

  setBroadcastMessage({ workshopId, trainerId, text }: WorkshopStateSetBroadcastMessageBody): Observable<WorkshopStateSetBroadcastMessageResult> {
    return this.apiService.post<WorkshopStateSetBroadcastMessageBody, WorkshopStateSetBroadcastMessageResult>(workshopStateSetBroadcastMessageUrl, { workshopId, trainerId, text });
  }
}
