import { WorkshopDataFile } from '../model/services/import-export';
import { apiV1BaseUrl } from './common';

type WorkshopImportParameters = {
  password: string
};

type WorkshopImportResult = {
  workshopId: string
};

export const workshopImportFetchUrl = `${apiV1BaseUrl}/import/fetch`;
export type WorkshopImportFetchBody = WorkshopImportParameters & { uniqueNumber: string };
export type WorkshopImportFetchResult = WorkshopImportResult;

export const workshopImportFileUrl = `${apiV1BaseUrl}/import/file`;
export type WorkshopImportFileBody = WorkshopImportParameters & { workshopDataFile: WorkshopDataFile };
export type WorkshopImportFileResult = WorkshopImportResult;

export const workshopResultDownloadUrl = `${apiV1BaseUrl}/export/download`;
export type WorkshopResultDownloadParameters = { workshopId: string, password: string, trainerId: string };

export const workshopResultTransferUrl = `${apiV1BaseUrl}/export/transfer`;
export type WorkshopResultTransferParameters = { workshopId: string, password: string, trainerId: string };
export type WorkshopResultTransferResult = { success: true };

export const backendAvailableUrl = `${apiV1BaseUrl}/backend/available`;
export type BackendAvailableParameters = {};
export type BackendAvailableResult = { available: boolean };
