import { NewEntity } from '@tstdl/base/database';
import { DocumentWithReference, NewDocumentWithReference } from './document';
import { Entity } from './entity';
import { FeedbackWithReference, NewFeedbackWithReference } from './feedback';
import { Language } from './language';
import { NewTestWithReference, TestWithReference } from './test';

export type Workshop = Entity & {
  name: string,
  uniqueNumber: string,
  countryCode: string,
  city: string,
  begin: number,
  end: number,
  langauges: Language[],
  requiresAgenda: boolean,
  passwordVersion: number,
  passwordSalt: string,
  passwordHash: string
};

export type NewWorkshop = NewEntity<Workshop>;

export type WorkshopWithReference = Workshop & { tests: TestWithReference[], documents: DocumentWithReference[], feedback?: FeedbackWithReference };
export type NewWorkshopWithReference = Omit<NewWorkshop, 'id'> & { tests: NewTestWithReference[], documents: NewDocumentWithReference[], feedback?: NewFeedbackWithReference };
