import { Participant } from './user';
import { WorkshopState } from './workshop-state';

export type DashboardDataTestRecordItem = {
  testRecordId: string,
  name: string,
  answeredQuestionsCount: number,
  totalQuestionsCount: number,
  started: boolean,
  submitted: boolean,
  millisecondsSpent: number
};

export type DashboardDataItem = {
  participant: Participant,
  helpRequested: boolean,
  hasFeedback: boolean,
  feedbackMissing: boolean,
  records: DashboardDataTestRecordItem[]
};

export type DashboardData = {
  workshopState: WorkshopState,
  participants: DashboardDataItem[]
};
