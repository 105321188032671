import { languages } from '../../common/languages';
import { EtestOnlineLocalization } from '../localization';

export const chinese: EtestOnlineLocalization = {
  language: languages.zh,
  keys: {
    address_type: '地址类型',
    agenda_upload_dialog_description: '该研讨会需要CWS议程。 请以PDF格式上传议程。',
    agenda_upload_dialog_header: '上传议程',
    agenda_upload_dialog_select_file: '单击以选择一个文件',
    agree_document: '同意',
    agree_documents_before_continue: '您必须同意这些文件才能继续',
    already_registered: '已经登记了',
    answered_questions: '已回答问题',
    auto_redirect: '您将会被自动重定向',
    back: '退回',
    begin: '开始',
    both: '两者都是',
    broadcast_message: '参会嘉宾',
    business_address: '业务',
    cancel: '取消',
    city: '城市',
    command_failed: '命令失败',
    command_sent: '命令发送至服务器',
    company: '公司',
    connection_required: '需要连接',
    contact_trainer: '请联系您的培训师',
    continue: '继续',
    controls: '控制',
    country: '国家',
    delete_member: '删除成员',
    delete_participant_confirm: '您确定要删除参与者 {{ firstName }} {{ lastName }} 吗？',
    delete_participant: '删除参与者',
    delete_workshop_confirm: '您真的要删除车间吗？',
    delete_workshop: '删除课程',
    delete: '删除',
    do_submit_and_end_workshop: '是，提交并返回测试选择',
    do_submit: '是的，提交',
    documents: '文件',
    dont_submit: '还没有',
    download_results_failed_message: '在导出测试时发生了错误。',
    download_results_message_text: '一旦导出生成，下载就会自动启动，可能需要等待一分钟。',
    download_results: '下载结果',
    download_workshop: '下载课程',
    duration: '持续',
    end_workshop_and_submit_feedback: '提交反馈并结束研讨会',
    end_workshop_without_feedback: '没有反馈就结束研讨会',
    end_workshop: '结束课程',
    end: '结束',
    error_no_connection: '无连接',
    error_tests_not_generated: '测试未生成，下载在eTest后台进行。',
    error_unknown_workshop: '未知的工作坊',
    error_unknown: '未知错误',
    error_webservice_not_available: '网络服务不可用',
    error_workshop_locked: '登录被锁定。 与您的教练联系。',
    checking_app_update_dialog_header: '检查更新',
    applying_app_update_dialog_header: '更新应用',
    unsupported_revision_message_box_title: '不支持的导入文件',
    unsupported_revision_message_box_message: '不支持导入文件的修订，请从eTest后端下载修订{{ supportedRevision }}。',
    application_update_available_message_box_title: '有可用更新',
    application_update_available_message_box_message: '此应用程序有更新。 页面将被重新加载。',
    exit: '退出',
    export_workshop: '导出课程',
    export: '导出',
    feedback_comment: '您是否有进一步改进的具体建议？',
    feedback_instruction: '1的评分意味着文本完全适用，而5表示它根本不适用。',
    feedback_language_hint: '提示：您可以通过菜单 "Language" -> "Feedback language" 更改反馈语言。',
    feedback_question_text: '文字',
    feedback_rating: '等级',
    feedback_thanks: '谢谢您的反馈！',
    feedback: '反馈',
    fetch_from_backend: '从后台获取',
    file: '文件',
    first_name: '名',
    fullscreen: '全屏',
    give_feedback_question: '您想要给出反馈吗？',
    how_to_export: '您需要如何导出课程？',
    incomplete: '不完整',
    internet_explorer_warning: '不支持Internet Explorer，可能会导致问题。 建议使用Chrome或Firefox。',
    invalid_country: '无效国家代码',
    invalid_file_or_wrong_password: '上传的文件损坏或提供的密码无效',
    language: '语言',
    last_name: '姓',
    loading_documents: '装载文档',
    loading_test: '装载测试…',
    lock_workshop: '锁定参与者登录',
    logout: '登出',
    mail: '电子邮箱',
    mark_question: '标注问题',
    marked_questions: '已标注问题',
    marked: '已标注',
    member_documents_info: '阅读所需文件并同意，以便继续',
    member_welcome_1: '欢迎使用在线测试系统',
    member_welcome_2: '为了进行测试，请填写以下表格并点击“继续”',
    member: '成员',
    minutes_abbreviation: '最小',
    mr: '先生',
    ms: '女士',
    no_participants: '尚无参与者注册。',
    no: '不是',
    ok: '好的',
    optional_document: '可选的',
    participants: '参与者',
    participate: '参与',
    password: '密码',
    period: '期',
    phone_number: '电话号码',
    please_wait: '请稍等',
    postal_code: '邮政编码',
    private_address: '私人',
    progress: '前进',
    rating_1: '很好',
    rating_2: '好',
    rating_3: '不满意',
    rating_4: '不好',
    record_session_mismatch_prompt_description: '测试 "{{ testName }}" 是从另一台设备继续进行的。 您要保留此设备上的当前状态还是丢弃它并继续使用其他测试？',
    record_session_mismatch_prompt_header: '测试冲突',
    record_session_mismatch_prompt_use_curret: '保持',
    record_session_mismatch_prompt_use_remote: '丢弃',
    release_workshop: '释放参与者登录',
    request_help: '请求帮助',
    requested_help: '帮助',
    return_to_test_selection: '返回测试选择',
    send: '发送',
    set_broadcast_message_dialog_header: '给参与者的讯息',
    shutdown_device: '关闭服务器',
    street: '街道',
    submission_confirmation_dialog_answers_cannot_be_changed: '在提交之后，答案将不能再更改',
    submission_confirmation_dialog_header: '是否确认？',
    submission_confirmation_dialog_open_tests: '剩余测试: {{ openTestsCount }}',
    submit_test: '提交测试',
    submit_workshop: '提交课程',
    submitted: '已提交',
    terms_language_hint: '注意：可以通过菜单 "Language" -> "Terms language" 更改术语的语言。',
    test: '测试',
    tests: '测验',
    thank_you_for_participation: '非常感谢大家的参与！',
    time_spent: '花时间',
    title: '题目',
    trainer_welcome: '请进行身份验证以便继续',
    trainer: '培训师',
    transfer_results_to_backend: '将结果传输到后端',
    transfer_success: '传送结果完成',
    transferring_results: '结果正在传送',
    try_later_or_use_file_upload: '稍后再尝试或使用文件上传',
    unanswered_questions: '未回答问题',
    unique_number: '唯一码',
    unmark_question: '删除标注',
    unsupported_filetype: '不支持的文件类型',
    unsynchronized_warning: '注意：不同步的更改。 不要关闭窗户。',
    upload_agenda: '上传议程',
    upload_file: '上传文件',
    upload: '上载',
    wait_for_sync_description: '等待同步未决的更改',
    wait_for_sync_header: '同步化',
    without_answer: '没有答案',
    workshop_country: '开课地点',
    workshop_importing: '导入课程…',
    workshop_not_available_import_needed: '目前没有课程需要导入，您需要如何导入？',
    yes: '是',
    zoom: '缩放'
  }
};
