import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LocalizationData } from '@tstdl/angular';
import { localizationKeys } from 'src/app/localization/localization-keys';

export type MessageBoxDialogData = {
  title: string | LocalizationData,
  message: string | LocalizationData
};

@Component({
  selector: 'app-message-box-dialog',
  templateUrl: './message-box-dialog.component.html',
  styleUrls: ['./message-box-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBoxDialogComponent {
  private readonly dialogRef: MatDialogRef<MessageBoxDialogComponent>;

  readonly localizationKeys = localizationKeys;

  readonly title: string | LocalizationData;
  readonly message: string | LocalizationData;

  constructor(dialogRef: MatDialogRef<MessageBoxDialogComponent>, @Inject(MAT_DIALOG_DATA) data: MessageBoxDialogData) {
    this.dialogRef = dialogRef;
    this.title = data.title;
    this.message = data.message;
  }

  close(): void {
    this.dialogRef.close();
  }
}
