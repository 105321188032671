import { Directive, HostBinding, Self } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName],[ngModel],[formControl]'
})
export class BootstrapValidationDirective {
  private readonly ngControl: NgControl;

  get control(): AbstractControl | null {
    return this.ngControl.control;
  }

  constructor(@Self() control: NgControl) {
    this.ngControl = control;
  }

  @HostBinding('class.is-valid')
  get isValid(): boolean {
    if (this.control == undefined) {
      return false;
    }

    const invalid = this.control.valid && this.control.touched;
    return invalid;
  }

  @HostBinding('class.is-invalid')
  get isInvalid(): boolean {
    if (this.control == undefined) {
      return false;
    }

    const invalid = this.control.invalid && this.control.touched;
    return invalid;
  }
}
