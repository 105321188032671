import { NewEntity } from '@tstdl/base/database';
import { Entity } from './entity';

export enum QuestionRecordType {
  MultipleChoice,
  Cloze
}

export type MultipleChoiceQuestionRecord = {
  questionId: string,
  type: QuestionRecordType.MultipleChoice,
  selectedAnswerIds: string[]
};

export type ClozeQuestionRecord = {
  questionId: string,
  type: QuestionRecordType.Cloze,
  selectedAnswerIds: { [clozeIndex: string]: string }
};

export type QuestionRecord = MultipleChoiceQuestionRecord | ClozeQuestionRecord;

export type TestRecord = Entity & {
  tag: string,
  session: string,
  participantId: string,
  workshopId: string,
  testId: string,
  markedQuestionIds: string[],
  started: boolean,
  submitted: boolean,
  millisecondsSpent: number,
  questionRecords: { [key: string]: QuestionRecord }
};

export type NewTestRecord = NewEntity<TestRecord>;
