import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnloadDeferrerService {
  private readonly syncSubject: BehaviorSubject<boolean>;

  readonly sync$: Observable<boolean>;

  get sync(): boolean {
    return this.syncSubject.value;
  }

  setSync(value: boolean): void {
    this.syncSubject.next(value);
  }

  constructor() {
    this.syncSubject = new BehaviorSubject<boolean>(false);
    this.sync$ = this.syncSubject.asObservable();
  }
}
