<h3 *tslLet="test$ as test" class="header centered-content horizontal">
  {{ test.name }} ({{ test.version }})
</h3>

<div class="content">
  <div class="actions flex-auto-wrap gap-3">
    <button app-button [color]="'secondary'" (click)="navigateBack()">{{ localizationKeys.back | localize }}</button>

    <button app-button *ngIf="!readonly" [color]="(questionMarked$ | async) ? 'marked' : 'secondary'"
            (click)="toggleMark()">{{ ((questionMarked$ | async) ? localizationKeys.unmark_question : localizationKeys.mark_question) | localize }}</button>

    <button app-button *ngIf="!readonly" [color]="'secondary'" (click)="submit()">{{ localizationKeys.submit_test | localize }}</button>

    <button app-button *ngIf="!readonly" [disabled]="!(connectionAvailable$ | async)" [color]="(helpRequested$ | async) ? 'warning' : 'secondary'"
            (click)="toggleRequestHelp()">{{ localizationKeys.request_help | localize }}</button>

    <div *ngIf="!readonly" class="time-spent-container d-flex flex-column justify-content-center">
      <div>{{ localizationKeys.time_spent | localize }}</div>
      <div>{{ timeSpentString$ | async }}</div>
    </div>

    <mat-slider min="1" max="2" step="0.1" [value]="fontSize" (input)="zoomChange($event)" [thumbLabel]="true" [displayWith]="zoomSliderFormat"></mat-slider>
  </div>

  <app-progressbar *ngIf="!readonly" [textType]="'of'"
                   [max]="(questions$ | async)!.length"
                   [value]="(answeredQuestionCount$ | async)!"
                   [color]="(allQuestionsAnswered$ | async) ? 'primary' : 'info'"></app-progressbar>

  <app-question-bar [questions]="(questions$ | async)!"
                    [selectedQuestion]="(selectedQuestion$ | async)!"
                    [answeredQuestions]="(answeredQuestions$ | async)!"
                    [markedQuestions]="(markedQuestions$ | async)!"
                    (questionSelected)="onQuestionSelected($event)"></app-question-bar>

  <app-question [readonly]="readonly"
                [question]="(selectedQuestion$ | async)!"
                [record]="(questionRecord$ | async)!"
                [language]="(language$ | async)!"
                [style.font-size.rem]="fontSize"
                (recordChange)="onRecordChange($event)"></app-question>
</div>
