import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppStabilizationService } from '@tstdl/angular';
import { Enumerable } from '@tstdl/base/enumerable';
import { BehaviorSubject, combineLatest, firstValueFrom, interval, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, startWith, switchMap, take, timeout } from 'rxjs/operators';
import { ParticipantLoginResult, ParticipantRegisterResult, TrainerLoginResult } from 'src/app/common/api';
import { getCommonTermsLanguages, getFeedbackLanguages, getTestLanguages } from 'src/app/common/data-analysing';
import { Country, Language, ParticipantContact, TestWithReference, User, Workshop, WorkshopWithReference } from 'src/app/common/model';
import { WorkshopState } from 'src/app/common/model/workshop-state';
import { localizationKeys } from 'src/app/localization/localization-keys';
import { MessageBoxDialogComponent, MessageBoxDialogData } from '../components/message-box-dialog/message-box-dialog.component';
import { WaitForSyncDialogComponent } from '../components/wait-for-sync-dialog/wait-for-sync-dialog.component';
import { WorkshopService } from './entities/workshop.service';
import { UnloadDeferrerService } from './unload-deferrer.service';
import { UserService } from './user.service';
import { WorkshopStateService } from './workshop-state.service';

export type LoginData = {
  mail: string,
  uniqueNumber: string,
  country: Country
};

export type TrainerLoginData = {
  mail: string,
  uniqueNumber: string,
  password: string
};

export type Registration = {
  user: User,
  workshop: Workshop
};

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private readonly userService: UserService;
  private readonly unloadDeferrerService: UnloadDeferrerService;
  private readonly workshopService: WorkshopService;
  private readonly workshopStateService: WorkshopStateService;
  private readonly matDialog: MatDialog;

  private readonly loginDataSubject: BehaviorSubject<LoginData | undefined>;
  private readonly checkedDocumentIdsSubject: BehaviorSubject<string[] | undefined>;
  private readonly trainerLoginDataSubject: BehaviorSubject<TrainerLoginData | undefined>;
  private readonly userSubject: BehaviorSubject<User | undefined>;
  private readonly workshopIdSubject: BehaviorSubject<string | undefined>;
  private readonly activeTestSubject: BehaviorSubject<TestWithReference | undefined>;
  private readonly testLanguageSetSubject: Subject<void>;
  private readonly feedbackLanguageSetSubject: ReplaySubject<Language | undefined>;
  private readonly termsLanguageSetSubject: ReplaySubject<Language | undefined>;
  private readonly selectedTestLanguageMap: Map<TestWithReference, Language>;
  private readonly updateWorkshopStateSubject: Subject<void>;

  readonly workshop$: Observable<WorkshopWithReference | undefined>;
  readonly workshopState$: Observable<WorkshopState | undefined>;
  readonly availableTestLanguages$: Observable<Language[] | undefined>;
  readonly availableFeedbackLanguages$: Observable<Language[] | undefined>;
  readonly availableTermsLanguages$: Observable<Language[] | undefined>;
  readonly selectedTestLanguage$: Observable<Language | undefined>;
  readonly selectedFeedbackLanguage$: Observable<Language | undefined>;
  readonly selectedTermsLanguage$: Observable<Language | undefined>;

  get workshopId$(): Observable<string | undefined> {
    return this.workshopIdSubject.asObservable();
  }

  get activeTest$(): Observable<TestWithReference | undefined> {
    return this.activeTestSubject.asObservable();
  }

  get hasLoginData(): boolean {
    return this.loginDataSubject.value != undefined;
  }

  get hasCheckedDocumentIds(): boolean {
    return this.checkedDocumentIdsSubject.value != undefined;
  }

  get isLoggedIn(): boolean {
    return this.userSubject.value != undefined;
  }

  get loginData(): LoginData {
    if (!this.hasCheckedDocumentIds) {
      throw new Error('checkedDocumentIds not specified');
    }

    return this.loginDataSubject.value as LoginData;
  }

  get checkedDocumentIds(): string[] {
    if (!this.hasLoginData) {
      throw new Error('loginData not specified');
    }

    return this.checkedDocumentIdsSubject.value as string[];
  }

  get user(): User {
    if (!this.isLoggedIn) {
      throw new Error('not logged in');
    }

    return this.userSubject.value as User;
  }

  get trainerLoginData(): TrainerLoginData {
    if (this.trainerLoginDataSubject.value == undefined) {
      throw new Error('not logged in');
    }

    return this.trainerLoginDataSubject.value;
  }

  constructor(appStabilizationService: AppStabilizationService, userService: UserService, workshopService: WorkshopService, workshopStateService: WorkshopStateService, unloadDeferrerService: UnloadDeferrerService, matDialog: MatDialog) {
    this.userService = userService;
    this.workshopService = workshopService;
    this.workshopStateService = workshopStateService;
    this.unloadDeferrerService = unloadDeferrerService;
    this.matDialog = matDialog;

    this.loginDataSubject = new BehaviorSubject<LoginData | undefined>(undefined);
    this.checkedDocumentIdsSubject = new BehaviorSubject<string[] | undefined>(undefined);
    this.trainerLoginDataSubject = new BehaviorSubject<TrainerLoginData | undefined>(undefined);
    this.userSubject = new BehaviorSubject<User | undefined>(undefined);
    this.workshopIdSubject = new BehaviorSubject<string | undefined>(undefined);
    this.activeTestSubject = new BehaviorSubject<TestWithReference | undefined>(undefined);
    this.testLanguageSetSubject = new BehaviorSubject<void>(undefined);
    this.feedbackLanguageSetSubject = new ReplaySubject(1);
    this.termsLanguageSetSubject = new ReplaySubject(1);
    this.updateWorkshopStateSubject = new Subject();
    this.selectedTestLanguageMap = new Map();

    this.workshop$ = this.workshopId$.pipe(
      switchMap((id) =>
        (id == undefined)
          ? of(undefined)
          : this.workshopService.load(id).pipe(startWith(undefined))
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.workshopState$ = appStabilizationService.wait$().pipe(switchMap(() => merge(interval(10000), this.workshop$, this.updateWorkshopStateSubject))).pipe(
      switchMap(() => this.workshop$.pipe(take(1))),
      switchMap((workshop) => workshop == undefined ? of(undefined) : this.workshopStateService.loadByWorkshop({ workshopId: workshop.id }).pipe(timeout(2000))),
      catchError(() => of(undefined)),
      distinctUntilChanged((a, b) => a?.tag == b?.tag),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.availableTestLanguages$ = this.activeTest$.pipe(
      map((test) => (test == undefined) ? undefined : getTestLanguages(test)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.availableFeedbackLanguages$ = this.workshop$.pipe(
      map((workshop) => workshop == undefined ? undefined : getFeedbackLanguages(workshop.feedback)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.availableTermsLanguages$ = this.workshop$.pipe(
      map((workshop) => workshop == undefined ? undefined : getCommonTermsLanguages(workshop.documents)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.selectedTestLanguage$ = combineLatest([this.activeTest$, this.availableTestLanguages$, this.testLanguageSetSubject]).pipe(
      map(([activeTest, availableTestLanguages]) => {
        if (activeTest == undefined || availableTestLanguages == undefined || availableTestLanguages.length == 0) {
          return undefined;
        }

        const selectedLanguage = this.selectedTestLanguageMap.get(activeTest);
        const preferredLanguages = [selectedLanguage, ...this.selectedTestLanguageMap.values()];

        for (const language of preferredLanguages) {
          const hasSelectedLanguage = language != undefined && Enumerable.from(availableTestLanguages).any((l) => l.code == language.code);

          if (hasSelectedLanguage) {
            return language;
          }
        }

        return availableTestLanguages[0];
      }),
      startWith(undefined),
      distinctUntilChanged((a, b) => (a == b) || (a != undefined && b != undefined && a.code == b.code)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const firstAvailablefeedbackLanguage$ = this.availableFeedbackLanguages$.pipe(
      map((languages) => languages == undefined ? undefined : languages[0]),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.selectedFeedbackLanguage$ = merge(firstAvailablefeedbackLanguage$, this.feedbackLanguageSetSubject).pipe(shareReplay({ bufferSize: 1, refCount: true }));

    const firstAvailableTermsLanguage$ = this.availableTermsLanguages$.pipe(
      map((languages) => languages == undefined ? undefined : languages[0]),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.selectedTermsLanguage$ = merge(firstAvailableTermsLanguage$, this.termsLanguageSetSubject).pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.initialize();
  }

  initialize(): void {
    this.handleBroadcastMessages();
  }

  setCheckedDocumentIds(ids: string[]): void {
    this.checkedDocumentIdsSubject.next(ids);
  }

  setTestLanguage(language: Language): void {
    this.activeTest$.pipe(take(1)).subscribe((test) => {
      if (test == undefined) {
        return;
      }

      this.selectedTestLanguageMap.set(test, language);
      this.testLanguageSetSubject.next();
    });
  }

  setFeedbackLanguage(language: Language): void {
    this.feedbackLanguageSetSubject.next(language);
  }

  setTermsLanguage(language: Language): void {
    this.termsLanguageSetSubject.next(language);
  }

  setActiveTest(test: TestWithReference | undefined): void {
    this.activeTestSubject.next(test);
  }

  async loginTrainer(loginData: TrainerLoginData): Promise<TrainerLoginResult> {
    const result = await firstValueFrom(this.userService.loginTrainer(loginData));

    if (result.success) {
      this.workshopIdSubject.next(result.workshopId);
      this.userSubject.next(result.trainer);
      this.trainerLoginDataSubject.next(loginData);
    }

    return result;
  }

  async loginParticipant({ mail, country, uniqueNumber }: { mail: string, country: Country, uniqueNumber: string }): Promise<ParticipantLoginResult> {
    const result = await firstValueFrom(this.userService.loginParticipant({ mail, countryCode: country.code, uniqueNumber }));

    if (result.success) {
      this.loginDataSubject.next({ mail, country, uniqueNumber });
      this.workshopIdSubject.next(result.workshopId);

      if (result.existing != undefined) {
        this.userSubject.next(result.existing.participant);
      }
    }

    return result;
  }

  async registerParticipant({ mail, contact, checkedDocumentIds }: { mail: string, contact: ParticipantContact, checkedDocumentIds: string[] }): Promise<ParticipantRegisterResult> {
    const result = await firstValueFrom(this.userService.registerParticipant({ workshopId: this.workshopIdSubject.value as string, mail, contact, checkedDocumentIds }));
    this.userSubject.next(result.participant);

    return result;
  }

  async logout(): Promise<void> {
    let dialog: undefined | MatDialogRef<WaitForSyncDialogComponent>;

    this.unloadDeferrerService.sync$.subscribe((syncing) => {
      if (syncing && dialog == undefined) {
        dialog = this.matDialog.open(WaitForSyncDialogComponent, { disableClose: true });
      }
      else {
        location.reload();
      }
    });

    return;
  }

  updateWorkshopState(): void {
    this.updateWorkshopStateSubject.next();
  }

  private handleBroadcastMessages(): void {
    this.workshopState$.pipe(
      distinctUntilChanged((a, b) => a?.broadcastMessage?.tag == b?.broadcastMessage?.tag),
      map((state) => state?.broadcastMessage?.text)
    )
      .subscribe((text) => {
        if (text != undefined) {
          const data: MessageBoxDialogData = {
            title: localizationKeys.broadcast_message,
            message: text
          };

          this.matDialog.open(MessageBoxDialogComponent, { data, disableClose: true });
        }
      });
  }
}
