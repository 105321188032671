import { NewEntity } from '@tstdl/base/database';
import { Entity } from './entity';
import { QuestionWithReference, NewQuestionWithReference } from './question';

export type Test = Entity & {
  name: string,
  version: string,
  workshopId: string
};

export type NewTest = NewEntity<Test>;

export type TestWithReference = Test & { questions: QuestionWithReference[] };
export type NewTestWithReference = Omit<NewTest, 'id' | 'workshopId'> & { questions: NewQuestionWithReference[] };
