import { Image, ImageWithReference, ImageWithReferenceWithoutId } from './image';

export type Text = {
  languageCode: string,
  value: string,
  image?: Image
};

export type TextWithReference = Omit<Text, 'image'> & { image?: ImageWithReference };
export type TextWithReferenceWithoutId = Omit<Text, 'image'> & { image?: ImageWithReferenceWithoutId };
