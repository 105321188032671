import { Entity } from '../model';

export type EntityMap<T extends Entity> = Map<string, T>;
export type EntityGroupMap<T extends Entity> = Map<string, T[]>;

export function getEntityMap<T extends Entity>(entities: Iterable<T>): EntityMap<T> {
  const map = new Map<string, T>();

  for (const entity of entities) {
    map.set(entity.id, entity);
  }

  return map;
}

export function getEntityGroupMap<T extends Entity>(entities: Iterable<T>, groupSelector: (entity: T) => string): EntityGroupMap<T> {
  const map = new Map<string, T[]>();

  for (const entity of entities) {
    const key = groupSelector(entity);

    if (!map.has(key)) {
      map.set(key, []);
    }

    (map.get(key) as T[]).push(entity);
  }

  return map;
}

export function getEntity<T extends Entity>(entityMap: EntityMap<T>, id: string): T {
  const entity = entityMap.get(id);

  if (entity == undefined) {
    throw new Error('entity not found');
  }

  return entity;
}

export function getEntities<T extends Entity>(entityMap: EntityMap<T>, ids: string[]): T[] {
  const entitiesArray = ids.map((id) => getEntity(entityMap, id));
  return entitiesArray;
}

export function getEntityGroup<T extends Entity>(entityGroupMap: EntityGroupMap<T>, group: string): T[] {
  const entities = entityGroupMap.get(group);

  if (entities == undefined) {
    throw new Error('entity not found');
  }

  return entities;
}
