import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ClozeAnswerWithReference, ImageWithReference, Language, TextWithReference } from 'src/app/common/model';

export type RenderPart = TextRenderPart | ImageRenderPart | ClozeRenderPart;

type RenderPartBase = { type: string };

export type TextRenderPart = RenderPartBase & {
  type: 'text',
  text: string;
};

export type ImageRenderPart = RenderPartBase & {
  type: 'image',
  image: ImageWithReference;
};

export type ClozeRenderPart = RenderPartBase & {
  type: 'cloze',
  clozeIndex: number;
  answers: ClozeAnswerWithReference[];
};

const TEXT_SPLIT_PARSE_REGEX = /(^(?:@Image|\{Image\})$)|(^Options=\{(\d+)\}$)/;
const TEXT_SPLIT_REGEX = /((?:@Image|\{Image\})|Options=\{\d+\})/;

@Injectable({
  providedIn: 'root'
})
export class TextService {
  private readonly cache: WeakMap<TextWithReference, RenderPart[]>;

  constructor() {
    this.cache = new WeakMap();
  }

  getCurrentLanguageText(language$: Observable<Language | undefined>, texts: (TextWithReference)[]): Observable<TextWithReference | undefined> {
    return language$.pipe(
      switchMap((language) => language == undefined ? of(undefined) : of(texts.find((text) => text.languageCode == language.code)))
    );
  }

  getRenderParts(language$: Observable<Language | undefined>, texts: (TextWithReference)[], clozeAnswers?: ClozeAnswerWithReference[]): Observable<RenderPart[]> {
    return this.getCurrentLanguageText(language$, texts).pipe(
      map((text) => text == undefined ? [] : this.parseText(text, clozeAnswers))
    );
  }

  private parseText(text: TextWithReference, clozeAnswers?: ClozeAnswerWithReference[]): RenderPart[] {
    const cached = this.cache.get(text);

    if (cached != undefined) {
      return cached;
    }

    const parsed = parseText(text, clozeAnswers);
    this.cache.set(text, parsed);

    return parsed;
  }
}

function parseText(text: TextWithReference, clozeAnswers?: ClozeAnswerWithReference[]): RenderPart[] {
  const textSplits = text.value.split(TEXT_SPLIT_REGEX);
  const renderParts = textSplits
    .filter((split) => (split.length > 0))
    .map((split) => parseTextSplit(text, split, clozeAnswers));

  return renderParts;
}

function parseTextSplit(sourceText: TextWithReference, textSplit: string, clozeAnswers?: ClozeAnswerWithReference[]): RenderPart {
  let renderPart: RenderPart;

  const match = textSplit.match(TEXT_SPLIT_PARSE_REGEX);

  if (match == undefined) {
    renderPart = { type: 'text', text: textSplit };
  }
  else {
    const [, image, cloze, clozeIndexText] = match;

    if (image != undefined) {
      renderPart = (sourceText.image == undefined)
        ? { type: 'text', text: '-- missing image, try another test language --' }
        : { type: 'image', image: sourceText.image };
    }
    else if (cloze != undefined) {
      if (clozeAnswers == undefined) {
        throw new Error('missing clozeAnswers');
      }

      const clozeIndex = parseInt(clozeIndexText!);
      const answers = clozeAnswers.filter((answer) => answer.clozeIndex == clozeIndex);

      if (answers.length == 0) {
        throw new Error(`no answer for cloze ${clozeIndex} available`);
      }

      renderPart = { type: 'cloze', answers, clozeIndex };
    }
    else {
      renderPart = { type: 'text', text: textSplit };
    }
  }

  return renderPart;
}
