<button type="button"
        [disabled]="disabled"
        class="dropdown-toggle form-control"
        [class.btn-sm]="size == 'small'"
        [class.btn-lg]="size == 'large'"
        [matMenuTriggerFor]="dropdownMenu">
  <span>{{ selected == undefined ? placeholder : selected.text }}</span>
</button>

<mat-menu #dropdownMenu="matMenu" (closed)="onMenuClosed()">
  <button mat-menu-item *ngFor="let item of items" (click)="onSelect(item)">{{ item.text }}</button>
</mat-menu>
