import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ClozeAnswerWithReference, Language } from 'src/app/common/model';
import { ClozeRenderPart, TextRenderPart, TextService } from 'src/app/modules/core/services/text.service';

@Component({
  selector: 'app-cloze-field',
  templateUrl: './cloze-field.component.html',
  styleUrls: ['./cloze-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClozeFieldComponent implements OnInit, OnChanges {
  private readonly textService: TextService;
  private readonly selectedAnswerSubject: Subject<ClozeAnswerWithReference | undefined>;
  private readonly languageSubject: Subject<Language | undefined>;

  @Input() renderPart: ClozeRenderPart;
  @Input() selectedAnswer: ClozeAnswerWithReference | undefined;
  @Input() language: Language | undefined;

  @Output() readonly answerSelected: EventEmitter<ClozeAnswerWithReference>;
  @Output() readonly removeSelection: EventEmitter<void>;

  selectedAnswerText$: Observable<string>;

  get answers(): ClozeAnswerWithReference[] {
    return this.renderPart.answers;
  }

  constructor(textService: TextService) {
    this.textService = textService;

    this.selectedAnswerSubject = new ReplaySubject(1);
    this.languageSubject = new ReplaySubject(1);
    this.answerSelected = new EventEmitter();
    this.removeSelection = new EventEmitter();
  }

  ngOnInit(): void {
    this.selectedAnswerText$ = this.selectedAnswerSubject.pipe(
      switchMap((answer) => answer == undefined ? of(undefined) : this.textService.getRenderParts(this.languageSubject.asObservable(), answer.texts)),
      map((renderPart) => renderPart == undefined ? '.....' : (renderPart[0] as TextRenderPart).text)
    );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.selectedAnswerSubject.next(this.selectedAnswer);
    this.languageSubject.next(this.language);
  }

  selectAnswer(answer: ClozeAnswerWithReference): void {
    this.answerSelected.emit(answer);
  }

  removeSelectionClick(): void {
    this.removeSelection.emit();
  }
}
