import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

const modules = [
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatMenuModule,
  MatSelectModule,
  MatSliderModule,
  MatSnackBarModule
];

@NgModule({
  declarations: [],
  imports: modules,
  exports: modules
})
export class AngularMaterialModule { }
