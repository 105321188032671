import { Injectable } from '@angular/core';
import { deserialize } from '@tstdl/base/serializer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkshopDeleteParameters, WorkshopDeleteResult, workshopDeleteUrl, WorkshopExistsParameters, WorkshopExistsResult, workshopExistsUrl, WorkshopGetIdParameters, WorkshopGetIdResult, workshopGetIdUrl, WorkshopLoadParameters, WorkshopLoadResult, workshopLoadUrl } from 'src/app/common/api';
import { WorkshopWithReference } from 'src/app/common/model';
import { ApiService, CacheType } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class WorkshopService {
  private readonly apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  existsByUniqueNumber(uniqueNumber: string): Observable<WorkshopExistsResult> {
    return this.apiService.get<WorkshopExistsParameters, WorkshopExistsResult>(workshopExistsUrl, { uniqueNumber });
  }

  getId(uniqueNumber: string): Observable<WorkshopGetIdResult> {
    return this.apiService.get<WorkshopGetIdParameters, WorkshopGetIdResult>(workshopGetIdUrl, { uniqueNumber });
  }

  load(id: string): Observable<WorkshopWithReference> {
    return this.apiService.get<WorkshopLoadParameters, WorkshopLoadResult>(workshopLoadUrl, { id }, CacheType.Full).pipe(
      map(({ serializedWorkshop }) => deserialize(serializedWorkshop))
    );
  }

  delete(id: string, trainerId: string): Observable<WorkshopDeleteResult> {
    return this.apiService.get<WorkshopDeleteParameters, WorkshopDeleteResult>(workshopDeleteUrl, { id, trainerId });
  }

  clearCache(): void {
    this.apiService.clearCache(workshopLoadUrl);
  }
}
