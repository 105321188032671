<div class="card question">
  <div class="card-body">
    <ng-container *ngFor="let renderPart of (renderParts$ | async)">
      <span *ngIf="renderPart.type == 'text'">
        {{ renderPart.text }}
      </span>

      <app-cloze-field *ngIf="renderPart.type == 'cloze'"
                       class="mx-1"
                       [renderPart]="renderPart"
                       [selectedAnswer]="getSelectedAnswer(renderPart.clozeIndex)"
                       [language]="language"
                       (answerSelected)="answerSelected($event)"
                       (removeSelection)="removeSelection(renderPart.clozeIndex)"></app-cloze-field>

      <div *ngIf="renderPart.type == 'image'">
        <app-image-render-part [image]="renderPart.image"></app-image-render-part>
      </div>
    </ng-container>
  </div>
</div>
