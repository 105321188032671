import { languages } from '../../common/languages';
import { EtestOnlineLocalization } from '../localization';

export const english: EtestOnlineLocalization = {
  language: languages.en,
  keys: {
    address_type: 'Address type',
    agenda_upload_dialog_description: 'This workshop requires a CWS-Agenda. Please Upload the agenda as a PDF file.',
    agenda_upload_dialog_header: 'Upload CWS-Agenda',
    agenda_upload_dialog_select_file: 'Click to select a file',
    agree_document: 'Accept',
    agree_documents_before_continue: 'You must agree to the terms to continue',
    already_registered: 'Already registered',
    answered_questions: 'Answered questions',
    auto_redirect: 'You will be automatically redirected',
    back: 'Back',
    begin: 'Begin',
    both: 'Both',
    broadcast_message: 'Message for participants',
    business_address: 'Business',
    cancel: 'Cancel',
    city: 'City',
    command_failed: 'Command failed',
    command_sent: 'Command was sent to server',
    company: 'Company',
    connection_required: 'connection required',
    contact_trainer: 'Contact your Trainer',
    continue: 'Continue',
    controls: 'Controls',
    country: 'Country',
    delete_member: 'Delete member',
    delete_participant_confirm: 'Are you sure you want to delete participant {{ firstName }} {{ lastName }}?',
    delete_participant: 'Delete participant',
    delete_workshop_confirm: 'Do you really want to delete the workshop irrevocably?',
    delete_workshop: 'Delete workshop',
    delete: 'Delete',
    do_submit_and_end_workshop: 'Yes, submit and end workshop',
    do_submit: 'Yes, submit and return to test selection',
    documents: 'Documents',
    dont_submit: 'Not yet',
    download_results_failed_message: 'An error occurred while exporting the tests.',
    download_results_message_text: 'The download will start automatically as soon as the export is generated. This may take up to a minute.',
    download_results: 'Download results',
    download_workshop: 'Download workshop',
    duration: 'Duration',
    end_workshop_and_submit_feedback: 'Submit feedback and end workshop',
    end_workshop_without_feedback: 'End workshop without feedback',
    end_workshop: 'End Workshop',
    end: 'End',
    error_no_connection: 'No Connection.',
    error_tests_not_generated: 'Tests not yet generated. Download in eTest backend required.',
    error_unknown_workshop: 'Unknown workshop',
    error_unknown: 'Unknown failure',
    error_webservice_not_available: 'Webservice not available',
    error_workshop_locked: 'Login is locked. Contact your trainer.',
    checking_app_update_dialog_header: 'Checking for updates',
    applying_app_update_dialog_header: 'Updating application',
    unsupported_revision_message_box_title: 'Unsupported import file',
    unsupported_revision_message_box_message: 'Revision of the import file is not supported, please download revision {{ supportedRevision }} from the eTest backend.',
    application_update_available_message_box_title: 'Update available',
    application_update_available_message_box_message: 'An update is available for this application. Page will be reloaded.',
    exit: 'Exit',
    export_workshop: 'Export workshop',
    export: 'Export',
    feedback_comment: 'Do you have any further specific suggestions for improvement?',
    feedback_instruction: "A rating of 1 means that the text fully applies, while 5 means it doesn't apply at all.",
    feedback_language_hint: 'Hint: You can change the feedback language via the menu "Language" -> "Feedback language".',
    feedback_question_text: 'Text',
    feedback_rating: 'Rating',
    feedback_thanks: 'Thank you for filling in the feedback.',
    feedback: 'Feedback',
    fetch_from_backend: 'Fetch from backend',
    file: 'File',
    first_name: 'First name',
    fullscreen: 'Fullscreen',
    give_feedback_question: 'Do you want to give feedback?',
    how_to_export: 'How would you like to export the workshop?',
    incomplete: 'incomplete',
    internet_explorer_warning: 'Internet Explorer is not supported and can cause problems. It is recommended to use Chrome or Firefox.',
    invalid_country: 'Invalid country',
    invalid_file_or_wrong_password: 'Uploaded file is corrupt or provided password invalid',
    language: 'Language',
    last_name: 'Last name',
    loading_documents: 'Loading documents',
    loading_test: 'Loading test...',
    lock_workshop: 'Lock participant login',
    logout: 'Logout',
    mail: 'E-Mail',
    mark_question: 'Mark question',
    marked_questions: 'Marked questions',
    marked: 'Marked',
    member_documents_info: 'Read the required documents and agree them in order to continue',
    member_welcome_1: 'Welcome to eTest Online',
    member_welcome_2: 'In order to proceed to the tests, please fill in the following form and press Continue',
    member: 'Member',
    minutes_abbreviation: 'min',
    mr: 'Mr.',
    ms: 'Ms.',
    no_participants: 'No participants are registered yet.',
    no: 'No',
    ok: 'Ok',
    optional_document: 'optional',
    participants: 'Participants',
    participate: 'Participate',
    password: 'Password',
    period: 'Period',
    phone_number: 'Phone number',
    please_wait: 'Please wait',
    postal_code: 'Postcode',
    private_address: 'Private',
    progress: 'Progress',
    rating_1: 'very good',
    rating_2: 'good',
    rating_3: 'unsatisfactory',
    rating_4: 'not good',
    record_session_mismatch_prompt_description: 'The test "{{ testName }}" was continued from another device. Do you want to keep the current state on this device or discard it and continue using the other test?',
    record_session_mismatch_prompt_header: 'Test conflict',
    record_session_mismatch_prompt_use_curret: 'Keep',
    record_session_mismatch_prompt_use_remote: 'Discard',
    release_workshop: 'Release participant login',
    request_help: 'Request help',
    requested_help: 'Help',
    return_to_test_selection: 'Return to test selection',
    send: 'Send',
    set_broadcast_message_dialog_header: 'Message to participants',
    shutdown_device: 'Shutdown server',
    street: 'Street',
    submission_confirmation_dialog_answers_cannot_be_changed: 'The answers cannot be changed after submission!',
    submission_confirmation_dialog_header: 'Are you sure?',
    submission_confirmation_dialog_open_tests: 'Remaining tests: {{ openTestsCount }}',
    submit_test: 'Submit test',
    submit_workshop: 'Submit workshop',
    submitted: 'Submitted',
    terms_language_hint: 'Hint: The language of the terms can be changed via the menu "Language" -> "Terms language".',
    test: 'Test',
    tests: 'Tests',
    thank_you_for_participation: 'Thank you very much for your participation!',
    time_spent: 'Spent time',
    title: 'Title',
    trainer_welcome: 'Please authenticate in order to continue',
    trainer: 'Trainer',
    transfer_results_to_backend: 'Transmit results to backend',
    transfer_success: 'Transmit of results completed',
    transferring_results: 'Results are being transmitted',
    try_later_or_use_file_upload: 'Try again later or use file-upload',
    unanswered_questions: 'Questions without answer',
    unique_number: 'Unique number',
    unmark_question: 'Remove mark',
    unsupported_filetype: 'Unsupported file type',
    unsynchronized_warning: 'Attention: Unsynchronized changes. Do not close the window.',
    upload_agenda: 'Upload agenda',
    upload_file: 'Upload file',
    upload: 'Upload',
    wait_for_sync_description: 'Waiting for synchronization of pending changes',
    wait_for_sync_header: 'Synchronization',
    without_answer: 'Without answer',
    workshop_country: 'Workshop location',
    workshop_importing: 'Importing Workshop...',
    workshop_not_available_import_needed: 'Workshop not yet available and must be imported, how do you want to import it?',
    yes: 'Yes',
    zoom: 'Zoom'
  }
};
