import type { Serialized } from '@tstdl/base/serializer';
import type { WorkshopWithReference } from '../model';
import { apiV1BaseUrl } from './common';
import type { EntityLoadParameters } from './entity';
import { getEntityLoadUrl } from './entity';

export const workshopExistsUrl = `${apiV1BaseUrl}/workshop/exists/:uniqueNumber`;
export type WorkshopExistsParameters = { uniqueNumber: string };
export type WorkshopExistsResult = boolean;

export const workshopGetIdUrl = `${apiV1BaseUrl}/workshop/id/:uniqueNumber`;
export type WorkshopGetIdParameters = { uniqueNumber: string };
export type WorkshopGetIdResult = string;

export const workshopLoadUrl = getEntityLoadUrl('workshop');
export type WorkshopLoadParameters = EntityLoadParameters;
export type WorkshopLoadResult = { serializedWorkshop: Serialized<WorkshopWithReference> };

export const workshopDeleteUrl = `${apiV1BaseUrl}/workshop/delete/:id`;
export type WorkshopDeleteParameters = { id: string, trainerId: string };
export type WorkshopDeleteResult = { success: true };
