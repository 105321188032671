<div class="progress">
  <div role="progressbar"
       class="progress-bar bg-{{ color }}"
       [style.width.%]="indeterminate ? 100 : ((roundedValue / (max - min)) * 100)"
       [ngClass]="{ 'progress-bar-striped': indeterminate, 'progress-bar-animated': indeterminate }">
    <ng-container *ngIf="!indeterminate && showText">
      <span class="progress-text">
        <ng-container *ngIf="textType == 'percentage'; else of">
          {{ (roundedValue * 100) }}%
        </ng-container>

        <ng-template #of>
          {{ roundedValue }} / {{ max }}
        </ng-template>
      </span>
    </ng-container>
  </div>
</div>
