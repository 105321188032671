import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DeepReadonly } from '@tstdl/base/types';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AnswerWithReference, Language, MultipleChoiceQuestionRecord, MultipleChoiceQuestionWithReference, QuestionWithReference } from 'src/app/common/model';
import { RenderPart, TextService } from 'src/app/modules/core/services/text.service';

@Component({
  selector: 'app-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleChoiceQuestionComponent implements OnInit, OnChanges {
  private readonly textService: TextService;
  private readonly questionSubject: Subject<QuestionWithReference>;
  private readonly languageSubject: Subject<Language | undefined>;

  @Input() question: MultipleChoiceQuestionWithReference;
  @Input() record: MultipleChoiceQuestionRecord;
  @Input() language: Language | undefined;
  @Input() readonly: boolean;

  @Output() readonly recordChange: EventEmitter<MultipleChoiceQuestionRecord>;

  renderParts$: Observable<RenderPart[]>;

  constructor(textService: TextService) {
    this.textService = textService;

    this.questionSubject = new ReplaySubject(1);
    this.languageSubject = new ReplaySubject(1);
    this.recordChange = new EventEmitter();
    this.readonly = false;
  }

  ngOnInit(): void {
    this.renderParts$ = this.questionSubject.pipe(
      switchMap((question) => this.textService.getRenderParts(this.languageSubject.asObservable(), question.texts))
    );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.questionSubject.next(this.question);
    this.languageSubject.next(this.language);
  }

  onAnswerToggle(answer: AnswerWithReference): void {
    const updatedRecord = this.isSelected(answer)
      ? deselectAnswer(this.record, answer)
      : selectAnswer(this.record, answer);

    this.recordChange.emit(updatedRecord);
  }

  isSelected(answer: AnswerWithReference): boolean {
    const isSelected = !this.readonly && this.record.selectedAnswerIds.some((id) => id == answer.id);
    return isSelected;
  }
}

function deselectAnswer(record: DeepReadonly<MultipleChoiceQuestionRecord>, answer: AnswerWithReference): MultipleChoiceQuestionRecord {
  const updatedRecord: MultipleChoiceQuestionRecord = {
    ...record,
    selectedAnswerIds: record.selectedAnswerIds.filter((id) => id != answer.id)
  };

  return updatedRecord;
}

function selectAnswer(record: DeepReadonly<MultipleChoiceQuestionRecord>, answer: AnswerWithReference): MultipleChoiceQuestionRecord {
  const updatedRecord: MultipleChoiceQuestionRecord = {
    ...record,
    selectedAnswerIds: [...record.selectedAnswerIds, answer.id]
  };

  return updatedRecord;
}
