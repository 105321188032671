import { Component, OnInit } from '@angular/core';
import { AppStabilizationService, LocalizationService } from '@tstdl/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Language } from './common/model';
import { localizationKeys } from './localization/localization-keys';
import { localizations } from './localization/localizations';
import { ApiService } from './modules/core/services/api.service';
import { TestRecordService } from './modules/core/services/entities/test-record.service';
import { NotificationService } from './modules/core/services/notification.service';
import { SessionService } from './modules/core/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly localizationService: LocalizationService;
  private readonly notificationService: NotificationService;
  private readonly sessionService: SessionService;

  readonly localizationKeys = localizationKeys;
  readonly apiService: ApiService;
  readonly testRecordService: TestRecordService;

  get connectionAvailable$(): Observable<boolean> {
    return this.apiService.connectionAvailable$;
  }

  get uiLanguages(): Language[] {
    return Array.from(this.localizationService.availableLanguages);
  }

  get testLanguages$(): Observable<Language[] | undefined> {
    return this.sessionService.availableTestLanguages$;
  }

  get feedbackLanguages$(): Observable<Language[] | undefined> {
    return this.sessionService.availableFeedbackLanguages$;
  }

  get termsLanguages$(): Observable<Language[] | undefined> {
    return this.sessionService.availableTermsLanguages$;
  }

  get isLoggedIn(): boolean {
    return this.sessionService.isLoggedIn;
  }

  get pendingAgeSeconds$(): Observable<number> {
    return this.testRecordService.pendingAge$.pipe(map((ms) => Math.round((ms + 500) / 1000) - 1));
  }

  constructor(appStabilizationService: AppStabilizationService, apiService: ApiService, testRecordService: TestRecordService, localizationService: LocalizationService, sessionService: SessionService, notificationService: NotificationService) {
    this.apiService = apiService;
    this.testRecordService = testRecordService;
    this.localizationService = localizationService;
    this.sessionService = sessionService;
    this.notificationService = notificationService;

    for (const localization of localizations) {
      this.localizationService.registerLocalization(localization);
    }

    appStabilizationService.wait().then(() => console.log('app is stable'));
  }

  ngOnInit(): void {
    this.testRecordService.pendingCount$.subscribe((pending) => {
      window.onbeforeunload = (pending > 0)
        ? () => 'some pending changes are not synchronized. Please wait for connection.'
        : null;
    });
  }

  setUiLanguage(language: Language): void {
    this.localizationService.setLanguage(language);
  }

  setTestLanguage(language: Language): void {
    this.sessionService.setTestLanguage(language);
  }

  setFeedbackLanguage(language: Language): void {
    this.sessionService.setFeedbackLanguage(language);
  }

  setTermsLanguage(language: Language): void {
    this.sessionService.setTermsLanguage(language);
  }

  async onLogout(): Promise<void> {
    await this.sessionService.logout();
  }

  async onToggleFullscreen(): Promise<void> {
    const doc: CrossBrowserDocument = document as any;

    const isFullscreen = doc.fullscreenElement != undefined
      || doc.webkitFullscreenElement != undefined
      || doc.mozFullScreenElement != undefined;

    const requestFullscreen = doc.documentElement.requestFullscreen != undefined ? doc.documentElement.requestFullscreen.bind(document.documentElement)
      : doc.documentElement.webkitRequestFullscreen != undefined ? doc.documentElement.webkitRequestFullscreen.bind(doc.documentElement)
        : doc.documentElement.mozRequestFullScreen != undefined ? doc.documentElement.mozRequestFullScreen.bind(doc.documentElement)
          : undefined;

    const exitFullscreen = doc.exitFullscreen != undefined ? doc.exitFullscreen.bind(doc)
      : doc.webkitExitFullscreen != undefined ? doc.webkitExitFullscreen.bind(doc)
        : doc.mozCancelFullScreen != undefined ? doc.mozCancelFullScreen.bind(doc)
          : undefined;

    if (requestFullscreen == undefined || exitFullscreen == undefined) {
      this.notificationService.info('fullscreen is not supported in your browser');
      return;
    }

    if (!isFullscreen) {
      await requestFullscreen();
    }
    else {
      await exitFullscreen();
    }
  }
}

interface CrossBrowserDocument extends Document {
  readonly documentElement: CrossBrowserHTMLElement;

  readonly fullscreenElement: Element | null;
  readonly webkitFullscreenElement: Element | null;
  readonly mozFullScreenElement: Element | null;

  exitFullscreen(): Promise<void>;
  webkitExitFullscreen?(): Promise<void>;
  mozCancelFullScreen?(): Promise<void>;
}

interface CrossBrowserHTMLElement extends HTMLElement {
  requestFullscreen(options?: FullscreenOptions): Promise<void>;
  webkitRequestFullscreen?(options?: FullscreenOptions): Promise<void>;
  mozRequestFullScreen?(options?: FullscreenOptions): Promise<void>;
}
