import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { round } from '@tstdl/base/utils';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressbarComponent {
  @Input() min: number;
  @Input() max: number;
  @Input() value: number | undefined;
  @Input() showText: boolean;
  @Input() textType: 'percentage' | 'of';
  @Input() color: string;
  @Input() indeterminate: boolean;

  get roundedValue(): number {
    return round(this.value ?? 0, 2);
  }

  constructor() {
    this.min = 0;
    this.max = 1;
    this.value = 0;
    this.showText = true;
    this.textType = 'percentage';
    this.color = 'primary';
    this.indeterminate = false;
  }
}
