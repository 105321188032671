import { registerErrorHandler } from '@tstdl/base/api';
import { registerDefaultErrorHandlers } from '@tstdl/base/api/error-handlers';
import { NotFoundError, UnauthorizedError } from '@tstdl/base/error';
import type { Type } from '@tstdl/base/types';
import { BackendWebServiceError, InvalidPasswordError, ResultExportError, TestRecordSaveError, WorkshopImportError } from '../errors';
import { RegisterError } from '../errors/register-error';

export const apiV1BaseUrl = '/api/v1';

export const supressedErrors: Type<Error>[] = [
  InvalidPasswordError,
  NotFoundError,
  RegisterError,
  ResultExportError,
  TestRecordSaveError,
  UnauthorizedError
];

export function registerErrorHandlers(): void {
  registerDefaultErrorHandlers();
  registerErrorHandler(BackendWebServiceError, 500, (error) => error.message, (message) => new BackendWebServiceError(message));
  registerErrorHandler(InvalidPasswordError, 403, () => undefined, () => new InvalidPasswordError());
  registerErrorHandler(RegisterError, 403, (error) => error.reason, (reason) => new RegisterError(reason));
  registerErrorHandler(ResultExportError, 423, (error) => error.reason, (reason) => new ResultExportError(reason));
  registerErrorHandler(TestRecordSaveError, 409, (error) => ({ reason: error.reason, record: error.currentTestRecord }), ({ reason, record }) => new TestRecordSaveError(reason, record));
  registerErrorHandler(WorkshopImportError, 409, ({ reason, data }) => ({ reason, data }), ({ reason, data }) => new WorkshopImportError(reason, data));
}
