import { EntityGroupMap, EntityMap, getEntityGroupMap, getEntityMap } from '../utils/entity-map';
import { Answer } from './answer';
import { Document } from './document';
import { Feedback, FeedbackQuestion, FeedbackRecord } from './feedback';
import { File } from './file';
import { Question } from './question';
import { Test } from './test';
import { TestRecord } from './test-record';
import { Participant } from './user';
import { Workshop } from './workshop';

export type WorkshopData = {
  agendaFile?: File,
  answers: Answer[],
  documents: Document[],
  feedback?: Feedback,
  feedbackQuestions?: FeedbackQuestion[],
  feedbackRecords?: FeedbackRecord[],
  participants: Participant[],
  questions: Question[],
  testRecords: TestRecord[],
  tests: Test[],
  workshop: Workshop
};

export type MappedWorkshopData = {
  answerMap: EntityMap<Answer>,
  documentMap: EntityMap<Document>,
  feedback?: Feedback,
  feedbackQuestionMap?: EntityMap<FeedbackQuestion>,
  feedbackQuestionsMap?: EntityGroupMap<FeedbackQuestion>,
  feedbackRecordMap?: EntityMap<FeedbackRecord>,
  participantMap: EntityMap<Participant>,
  questionAnswersMap: EntityGroupMap<Answer>,
  questionMap: EntityMap<Question>,
  testMap: EntityMap<Test>,
  testQuestionsMap: EntityGroupMap<Question>
  testRecordMap: EntityMap<TestRecord>,
  workshop: Workshop
};

export function toMappedWorkshopData(workshopData: WorkshopData): MappedWorkshopData {
  const mappedWorkshopData: MappedWorkshopData = {
    answerMap: getEntityMap(workshopData.answers),
    documentMap: getEntityMap(workshopData.documents),
    feedback: workshopData.feedback,
    feedbackQuestionMap: workshopData.feedbackQuestions != undefined ? getEntityMap(workshopData.feedbackQuestions) : undefined,
    feedbackQuestionsMap: workshopData.feedbackQuestions != undefined ? getEntityGroupMap(workshopData.feedbackQuestions, (question) => question.feedbackId) : undefined,
    feedbackRecordMap: workshopData.feedbackRecords != undefined ? getEntityMap(workshopData.feedbackRecords) : undefined,
    participantMap: getEntityMap(workshopData.participants),
    questionAnswersMap: getEntityGroupMap(workshopData.answers, (answer) => answer.questionId),
    questionMap: getEntityMap(workshopData.questions),
    testMap: getEntityMap(workshopData.tests),
    testQuestionsMap: getEntityGroupMap(workshopData.questions, (question) => question.testId),
    testRecordMap: getEntityMap(workshopData.testRecords),
    workshop: workshopData.workshop
  };

  return mappedWorkshopData;
}
