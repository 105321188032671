import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Language } from 'src/app/common/model';
import { localizationKeys } from 'src/app/localization/localization-keys';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
  readonly localizationKeys = localizationKeys;

  show: boolean;

  @Input() uiLanguages: Language[];
  @Input() testLanguages: Language[] | undefined | null;
  @Input() feedbackLanguages: Language[] | undefined | null;
  @Input() termsLanguages: Language[] | undefined | null;
  @Input() isLoggedIn: boolean;

  @Output() readonly uiLanguageSelected: EventEmitter<Language>;
  @Output() readonly testLanguageSelected: EventEmitter<Language>;
  @Output() readonly feedbackLanguageSelected: EventEmitter<Language>;
  @Output() readonly termsLanguageSelected: EventEmitter<Language>;
  @Output() readonly logout: EventEmitter<void>;
  @Output() readonly toggleFullscreen: EventEmitter<void>;

  constructor() {
    this.show = false;
    this.uiLanguages = [];
    this.testLanguages = undefined;
    this.feedbackLanguages = undefined;
    this.termsLanguages = undefined;
    this.isLoggedIn = false;

    this.uiLanguageSelected = new EventEmitter();
    this.testLanguageSelected = new EventEmitter();
    this.feedbackLanguageSelected = new EventEmitter();
    this.termsLanguageSelected = new EventEmitter();
    this.logout = new EventEmitter();
    this.toggleFullscreen = new EventEmitter();
  }

  setUiLanguage(language: Language): void {
    this.uiLanguageSelected.emit(language);
  }

  setTestLanguage(language: Language): void {
    this.testLanguageSelected.emit(language);
  }

  setFeedbackLanguage(language: Language): void {
    this.feedbackLanguageSelected.emit(language);
  }

  setTermsLanguage(language: Language): void {
    this.termsLanguageSelected.emit(language);
  }

  onLogout(): void {
    this.logout.emit();
  }

  onToggleFullscreen(): void {
    this.toggleFullscreen.emit();
  }
}
