import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type DropdownItem<T = any> = {
  text: string,
  data: T
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownComponent),
    }
  ]
})
export class DropdownComponent<T> implements ControlValueAccessor, OnChanges {
  private readonly changeDetectorRef: ChangeDetectorRef;

  private _onChange: (value: any) => void;
  private _onTouched: () => void;

  @Input() placeholder: string;
  @Input() size: string;
  @Input() disabled: boolean;
  @Input() items: DropdownItem<T>[];
  @Input() selected: DropdownItem<T> | undefined;
  @Input() trackBy: (item: DropdownItem<T>) => any;

  @Output() readonly disabledChange: EventEmitter<boolean>;
  @Output() readonly selectedChange: EventEmitter<DropdownItem<T> | undefined>;

  constructor(changeDetectorRef: ChangeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;

    this._onChange = () => { /* ignore if not set by reactive forms */ };
    this._onTouched = () => { /* ignore if not set by reactive forms */ };

    this.placeholder = '';
    this.size = 'normal';
    this.disabled = false;
    this.selected = undefined;
    this.trackBy = (item: DropdownItem<T>) => item.data;
    this.selectedChange = new EventEmitter();
    this.disabledChange = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('items' in changes) {
      if (!Array.isArray(this.items)) {
        throw new Error('items must be an array of DropdownItem');
      }

      for (const item of this.items) {
        if (!isDropdownItem(item)) {
          throw new Error('invalid item');
        }
      }
    }

    if (('items' in changes) && !('selected' in changes) && this.selected != undefined) {
      this.selected = this.items.find((item) => this.trackBy(item) == this.trackBy(this.selected as DropdownItem<T>));
      this.selectedChange.emit(this.selected);
      this.changeDetectorRef.markForCheck();
    }
  }

  onSelect(item: DropdownItem<T>): void {
    this.selected = item;
    this.selectedChange.emit(item);
    this._onChange(item);
  }

  onMenuClosed(): void {
    this._onTouched();
  }

  writeValue(obj: DropdownItem<T> | undefined): void {
    this.selected = obj;
    this.selectedChange.next(obj);
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.disabledChange.emit(isDisabled);
    this.changeDetectorRef.markForCheck();
  }
}

function isDropdownItem(object: unknown): object is DropdownItem {
  const isDropdownItem =
    typeof (object as DropdownItem).text == 'string'
    && (object as DropdownItem).hasOwnProperty('data');

  return isDropdownItem;
}
