<div class="card question">
  <div class="card-body">
    <ng-container *ngFor="let renderPart of (renderParts$ | async)">
      <div *ngIf="renderPart.type == 'text'">
        {{ renderPart.text }}
      </div>

      <div *ngIf="renderPart.type == 'image'">
        <app-image-render-part [image]="renderPart.image"></app-image-render-part>
      </div>
    </ng-container>
  </div>
</div>

<div class="list-group answers">
  <button app-multiple-choice-answer
          *ngFor="let answer of question.answers"
          type="button"
          class="list-group-item list-group-item-action"
          [class.active]="isSelected(answer)"
          [answer]="answer"
          [language]="language"
          [readonly]="readonly"
          (toggle)="onAnswerToggle(answer)">
  </button>
</div>
