import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { timeout } from '@tstdl/base/utils';
import { combineLatest, interval, Observable, race, ReplaySubject, Subject, timer } from 'rxjs';
import { bufferCount, delayWhen, distinctUntilChanged, map, repeat, retry, shareReplay, startWith, switchMap, take, takeUntil, timeInterval } from 'rxjs/operators';
import { arrayHasLength$, getAnsweredQuestions$, getArrayLength$, getMarkedQuestions$, getQuestions$, getTimeSpentString$ } from 'src/app/common/data-analysing';
import { Language, QuestionRecord, QuestionRecordType, QuestionType, QuestionWithReference, TestRecord, User, UserType } from 'src/app/common/model';
import { TestWithReference } from 'src/app/common/model/test';
import { localizationKeys } from 'src/app/localization/localization-keys';
import { ApiService } from '../../services/api.service';
import { TestRecordService } from '../../services/entities/test-record.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { HelpRequestService } from '../../services/help-request.service';
import { SessionService } from '../../services/session.service';

export type TestComponentNavigationState = {
  selectedQuestionId: string
};

let globalFontSize = 1;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class.has-header]': 'true' }
})
export class TestComponent implements OnInit, OnDestroy {
  private readonly router: Router;
  private readonly route: ActivatedRoute;
  private readonly apiService: ApiService;
  private readonly testRecordService: TestRecordService;
  private readonly sessionService: SessionService;
  private readonly helpRequestService: HelpRequestService;
  private readonly errorHandlerService: ErrorHandlerService;

  private readonly destroySubject: Subject<void>;
  private readonly userSubject: Subject<User>;
  private readonly testSubject: Subject<TestWithReference>;
  private readonly testRecordSubject: Subject<TestRecord>;
  private readonly updateHelpRequestedSubject: Subject<void>;

  readonly localizationKeys = localizationKeys;

  readonly: boolean;

  user$: Observable<User>;
  test$: Observable<TestWithReference>;
  testRecord$: Observable<TestRecord>;
  questions$: Observable<QuestionWithReference[]>;
  selectedQuestion$: Subject<QuestionWithReference>;
  questionRecord$: Observable<QuestionRecord>;
  answeredQuestions$: Observable<QuestionWithReference[]>;
  markedQuestions$: Observable<QuestionWithReference[]>;
  answeredQuestionCount$: Observable<number>;
  allQuestionsAnswered$: Observable<boolean>;
  questionMarked$: Observable<boolean>;
  timeSpentString$: Observable<string>;
  helpRequested$: Observable<boolean>;

  get language$(): Observable<Language | undefined> {
    return this.sessionService.selectedTestLanguage$;
  }

  get connectionAvailable$(): Observable<boolean> {
    return this.apiService.connectionAvailable$;
  }

  get fontSize(): number {
    return globalFontSize;
  }

  set fontSize(value: number) {
    globalFontSize = value;
  }

  constructor(router: Router, route: ActivatedRoute, apiService: ApiService, testRecordService: TestRecordService, sessionService: SessionService, helpRequestService: HelpRequestService, errorHandlerService: ErrorHandlerService) {
    this.router = router;
    this.route = route;
    this.apiService = apiService;
    this.sessionService = sessionService;
    this.testRecordService = testRecordService;
    this.helpRequestService = helpRequestService;
    this.errorHandlerService = errorHandlerService;

    this.readonly = false;
    this.destroySubject = new ReplaySubject(1);
    this.userSubject = new ReplaySubject(1);
    this.testSubject = new ReplaySubject(1);
    this.testRecordSubject = new ReplaySubject(1);
    this.selectedQuestion$ = new ReplaySubject(1);
    this.updateHelpRequestedSubject = new Subject();

    this.user$ = this.userSubject.asObservable();
    this.test$ = this.testSubject.asObservable();
    this.testRecord$ = this.testRecordSubject.asObservable();

    this.questionRecord$ = combineLatest([this.selectedQuestion$, this.testRecordSubject]).pipe(
      map(([question, testRecord]) => {
        const record = testRecord.questionRecords[question.id];
        return record == undefined
          ? getEmptyQuestionRecord(question)
          : record;
      })
    );

    this.questions$ = getQuestions$(this.test$);
    this.answeredQuestions$ = getAnsweredQuestions$(this.questions$, this.testRecord$).pipe(startWith([]), shareReplay({ bufferSize: 1, refCount: true }));
    this.markedQuestions$ = getMarkedQuestions$(this.questions$, this.testRecord$).pipe(startWith([]), shareReplay({ bufferSize: 1, refCount: true }));
    this.answeredQuestionCount$ = getArrayLength$(this.answeredQuestions$);
    this.allQuestionsAnswered$ = arrayHasLength$(this.questions$, this.answeredQuestionCount$);
    this.questionMarked$ = combineLatest([this.testRecord$, this.selectedQuestion$]).pipe(map(([testRecord, question]) => testRecord.markedQuestionIds.includes(question.id)));
    this.timeSpentString$ = getTimeSpentString$(this.testRecord$);
  }

  ngOnInit(): void {
    this.route.data.subscribe(({ user, test, testRecord, helpRequested }) => {
      this.userSubject.next(user as User);
      this.testSubject.next(test as TestWithReference);

      timeout().then(() => this.sessionService.setActiveTest(test as TestWithReference));

      if (testRecord != undefined) {
        this.testRecordSubject.next(testRecord as TestRecord);

        let update: Partial<TestRecord> = {};

        if (!(testRecord as TestRecord).started) {
          update = { ...update, started: true };
        }

        if ((testRecord as TestRecord).session != this.testRecordService.session) {
          update = { ...update, session: this.testRecordService.session };
        }

        if (Object.keys(update).length > 0) {
          this.testRecordService.updateTestRecord((testRecord as TestRecord).id, update, true);
          this.testRecordService.synchronizeNow();
        }
      }
      else {
        this.readonly = true;
      }

      const question = (history.state != undefined && (history.state as TestComponentNavigationState).selectedQuestionId != undefined)
        ? (test as TestWithReference).questions.find((question) => question.id == (history.state as TestComponentNavigationState).selectedQuestionId)!
        : (test as TestWithReference).questions[0]!;

      this.selectedQuestion$.next(question);

      this.helpRequested$ = this.user$.pipe(
        take(1),
        delayWhen(() => race([timer(5000), this.updateHelpRequestedSubject])),
        switchMap((user) => this.helpRequestService.getState({ participantId: user.id })),
        map((result) => result.state),
        retry(),
        repeat(),
        startWith((helpRequested) != undefined ? helpRequested as boolean : false),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    });

    combineLatest([this.testRecordSubject, this.testRecordService.testRecordOverwrite$])
      .pipe(
        takeUntil(this.destroySubject),
        distinctUntilChanged(([, newTestRecordA], [, newTestRecordB]) => newTestRecordA.tag == newTestRecordB.tag)
      )
      .subscribe(([currentTestRecord, newTestRecord]) => {
        if (currentTestRecord.id == newTestRecord.id) {
          this.testRecordSubject.next(newTestRecord);
        }
      });

    if (!this.readonly) {
      this.startTimeSpentTimer();
    }

    this.testRecord$.subscribe((testRecord) => {
      if (testRecord.submitted) {
        this.router.navigate(['../tests']).catch((error) => this.errorHandlerService.handleError(error as Error));
      }
    });

    combineLatest([this.testRecordSubject, this.testRecordService.testRecordNotFound$]).pipe(takeUntil(this.destroySubject)).subscribe(([currentTestRecord, { id }]) => {
      if (currentTestRecord.id == id) {
        this.sessionService.logout().catch((error) => this.errorHandlerService.handleError(error as Error));
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.sessionService.setActiveTest(undefined);
  }

  zoomSliderFormat(value: number): string {
    return `${value}x`;
  }

  zoomChange(change: MatSliderChange): void {
    if (change.value != undefined) {
      this.fontSize = change.value;
    }
  }

  navigateBack(): void {
    if (this.sessionService.user.type == UserType.Participant) {
      this.router.navigate(['../tests']).catch((error) => this.errorHandlerService.handleError(error as Error));
    }
    else {
      this.router.navigate(['dashboard'], { relativeTo: this.route.parent }).catch((error) => this.errorHandlerService.handleError(error as Error));
    }
  }

  onQuestionSelected(question: QuestionWithReference): void {
    this.selectedQuestion$.next(question);
  }

  @HostListener('window:keydown.arrowleft')
  previousQuestion(): void {
    combineLatest([this.questions$, this.selectedQuestion$]).pipe(take(1)).subscribe(([questions, currentQuestion]) => {
      const currentQuestionIndex = questions.indexOf(currentQuestion);

      if (currentQuestionIndex > 0) {
        this.onQuestionSelected(questions[currentQuestionIndex - 1]!);
      }
    });
  }

  @HostListener('window:keydown.arrowright')
  nextQuestion(): void {
    combineLatest([this.questions$, this.selectedQuestion$]).pipe(take(1)).subscribe(([questions, currentQuestion]) => {
      const currentQuestionIndex = questions.indexOf(currentQuestion);

      if (currentQuestionIndex < (questions.length - 1)) {
        this.onQuestionSelected(questions[currentQuestionIndex + 1]!);
      }
    });
  }

  toggleMark(): void {
    combineLatest([this.testRecordSubject, this.selectedQuestion$, this.questionMarked$]).pipe(take(1)).subscribe(([testRecord, question, marked]) => {
      const markedQuestionIds = marked ? testRecord.markedQuestionIds.filter((id) => id != question.id) : [...testRecord.markedQuestionIds, question.id];

      const update: Partial<TestRecord> = {
        markedQuestionIds
      };

      this.testRecordService.updateTestRecord(testRecord.id, update);
    });
  }

  submit(): void {
    this.route.paramMap.subscribe(async (paramMap) => {
      const testId = paramMap.get('testId');

      try {
        await this.router.navigate(['../submit', testId]);
      }
      catch (error) {
        this.errorHandlerService.handleError(error as Error);
      }
    });
  }

  toggleRequestHelp(): void {
    combineLatest([this.user$, this.test$, this.helpRequested$]).pipe(
      take(1),
      switchMap(([user, test, state]) => this.helpRequestService.setState({ participantId: user.id, workshopId: test.workshopId, state: !state }))
    ).subscribe(() => this.updateHelpRequestedSubject.next());
  }

  onRecordChange(questionResult: QuestionRecord): void {
    this.testRecordSubject.pipe(take(1)).subscribe((testRecord) => {
      const update: Partial<TestRecord> = {
        questionRecords: { ...testRecord.questionRecords, [questionResult.questionId]: questionResult }
      };

      this.testRecordService.updateTestRecord(testRecord.id, update);
    });
  }

  private startTimeSpentTimer(): void {
    interval(1000)
      .pipe(
        takeUntil(this.destroySubject),
        timeInterval(),
        bufferCount(5),
        map((intervals) => intervals.reduce((sum, { interval }) => sum + interval, 0))
      )
      .subscribe((milliseconds) => this.increaseMillisecondsSpent(milliseconds));
  }

  increaseMillisecondsSpent(milliseconds: number): void {
    this.testRecordSubject.pipe(take(1)).subscribe((testRecord) => {
      const update: Partial<TestRecord> = {
        millisecondsSpent: testRecord.millisecondsSpent + milliseconds
      };

      this.testRecordService.updateTestRecord(testRecord.id, update);
    });
  }
}

function getEmptyQuestionRecord(question: QuestionWithReference): QuestionRecord {
  return (question.type == QuestionType.MultipleChoice)
    ? {
      questionId: question.id,
      type: QuestionRecordType.MultipleChoice,
      selectedAnswerIds: []
    }
    : {
      questionId: question.id,
      type: QuestionRecordType.Cloze,
      selectedAnswerIds: {}
    };
}
