import { CustomError } from '@tstdl/base/error';
import { TestRecord } from '../model';

export enum TestRecordSaveErrorReason {
  Submitted,
  SessionMismatch
}

export class TestRecordSaveError extends CustomError {
  static readonly errorName = 'TestRecordSaveError';

  readonly reason: TestRecordSaveErrorReason;
  readonly currentTestRecord: TestRecord;

  constructor(reason: TestRecordSaveErrorReason, currentTestRecord: TestRecord) {
    super({ message: 'test-record save error' });

    this.reason = reason;
    this.currentTestRecord = currentTestRecord;
  }
}
