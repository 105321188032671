import { NewEntity } from '@tstdl/base/database';
import { Entity } from './entity';

export enum UserType {
  Trainer,
  Participant
}

export enum AddressType {
  Business = 0,
  Private = 1
}

export type User = Entity & {
  type: UserType,
  workshopId: string,
  mail: string
};

export type Trainer = User & {
  type: UserType.Trainer
};

export type Participant = User & {
  type: UserType.Participant,
  contact: ParticipantContact,
  checkedDocumentIds: string[]
};

export type ParticipantContact = {
  addressType: AddressType;
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  postCode: string;
  city: string;
  country: string;
  phone: string;
};

export type NewUser = NewEntity<User>;
export type NewTrainer = NewEntity<Trainer>;
export type NewParticipant = NewEntity<Participant>;

export function isParticipant(user: User): user is Participant {
  return user.type == UserType.Participant;
}

export function assertParticipant(user: User): void {
  if (!isParticipant(user)) {
    throw new Error('user type is not participant');
  }
}
