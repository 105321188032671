import { FeedbackRecord } from '../model';
import { apiV1BaseUrl } from './common';
import { EntityLoadParameters, EntityLoadResult, getEntityLoadUrl } from './entity';

export const feedbackRecordGetIdUrl = `${apiV1BaseUrl}/feedback-record/get-id`;
export type FeedbackRecordGetIdParameters = { participantId: string };
export type FeedbackRecordGetIdResult = string;

export const feedbackRecordLoadUrl = getEntityLoadUrl('feedback-record');
export type FeedbackRecordLoadParameters = EntityLoadParameters;
export type FeedbackRecordLoadResult = EntityLoadResult<FeedbackRecord>;

export const feedbackRecordSaveUrl = `${apiV1BaseUrl}/feedback-record/save`;
export type FeedbackRecordSaveBody = { record: FeedbackRecord };
export type FeedbackRecordSaveResult = { success: true };
