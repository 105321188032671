import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParticipantDeleteParameters, ParticipantDeleteResult, participantDeleteUrl, ParticipantLoginParameters, ParticipantLoginResult, participantLoginUrl, ParticipantRegisterBody, ParticipantRegisterResult, participantRegisterUrl, TrainerLoginParameters, TrainerLoginResult, trainerLoginUrl } from 'src/app/common/api';
import { ParticipantContact } from 'src/app/common/model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  loginTrainer({ mail, uniqueNumber, password }: { mail: string, uniqueNumber: string, password: string }): Observable<TrainerLoginResult> {
    return this.apiService.get<TrainerLoginParameters, TrainerLoginResult>(trainerLoginUrl, { mail, uniqueNumber, password });
  }

  loginParticipant({ mail, countryCode, uniqueNumber }: { mail: string, countryCode: string, uniqueNumber: string }): Observable<ParticipantLoginResult> {
    return this.apiService.get<ParticipantLoginParameters, ParticipantLoginResult>(participantLoginUrl, { mail, countryCode, uniqueNumber });
  }

  registerParticipant({ workshopId, mail, contact, checkedDocumentIds }: { workshopId: string, mail: string, contact: ParticipantContact, checkedDocumentIds: string[] }): Observable<ParticipantRegisterResult> {
    return this.apiService.post<ParticipantRegisterBody, ParticipantRegisterResult>(participantRegisterUrl, { workshopId, mail, contact, checkedDocumentIds });
  }

  deleteParticipant({ trainerId, participantId }: { trainerId: string, participantId: string }): Observable<ParticipantDeleteResult> {
    return this.apiService.get<ParticipantDeleteParameters, ParticipantDeleteResult>(participantDeleteUrl, { trainerId, participantId });
  }
}
