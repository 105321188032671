<ng-container *ngIf="questionType == 'multiple-choice'; else cloze">
  <app-multiple-choice-question
                                [readonly]="readonly"
                                [question]="multipleChoiceQuestionWithReference"
                                [record]="multipleChoiceQuestionRecord"
                                [language]="language"
                                (recordChange)="onRecordChange($event)"></app-multiple-choice-question>
</ng-container>

<ng-template #cloze>
  <app-cloze-question [readonly]="readonly"
                      [question]="clozeQuestionWithReference"
                      [record]="clozeQuestionRecord"
                      [language]="language"
                      (recordChange)="onRecordChange($event)"></app-cloze-question>
</ng-template>
