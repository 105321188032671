import { NewEntity } from '@tstdl/base/database';
import { Entity } from './entity';
import { Text, TextWithReference, TextWithReferenceWithoutId } from './text';

export enum AnswerType {
  MultipleChoice = 0,
  Cloze = 1
}

export type Answer = Entity & {
  workshopId: string,
  type: AnswerType,
  questionId: string,
  texts: Text[],
  isCorrect: boolean
};

export type MultipleChoiceAnswer = Answer & {
  type: AnswerType.MultipleChoice
};

export type ClozeAnswer = Answer & {
  type: AnswerType.Cloze,
  clozeIndex: number
};

export type NewAnswer = NewEntity<Answer>;
export type NewMultipleChoiceAnswer = NewEntity<MultipleChoiceAnswer>;
export type NewClozeAnswer = NewEntity<ClozeAnswer>;

export type AnswerWithReference = Omit<Answer, 'texts'> & { texts: TextWithReference[] };
export type MultipleChoiceAnswerWithReference = Omit<MultipleChoiceAnswer, 'texts'> & { texts: TextWithReference[] };
export type ClozeAnswerWithReference = Omit<ClozeAnswer, 'texts'> & { texts: TextWithReference[] };

export type NewAnswerWithReference = Omit<NewAnswer, 'id' | 'workshopId' | 'texts' | 'questionId'> & { texts: TextWithReferenceWithoutId[] };
export type NewMultipleChoiceAnswerWithReference = Omit<NewMultipleChoiceAnswer, 'id' | 'workshopId' | 'texts' | 'questionId'> & { texts: TextWithReferenceWithoutId[] };
export type NewClozeAnswerWithReference = Omit<NewClozeAnswer, 'id' | 'workshopId' | 'texts' | 'questionId'> & { texts: TextWithReferenceWithoutId[] };
