import { WorkshopState } from '../model/workshop-state';
import { apiV1BaseUrl } from './common';

export const workshopStateLoadByWorkshopUrl = `${apiV1BaseUrl}/workshop-state/load/:workshopId`;
export type WorkshopStateLoadByWorkshopParameters = { workshopId: string };
export type WorkshopStateLoadByWorkshopResult = WorkshopState;

export const workshopStateReleaseUrl = `${apiV1BaseUrl}/workshop-state/release`;
export type WorkshopStateReleaseParameters = { workshopId: string, trainerId: string };
export type WorkshopStateReleaseResult = { success: true };

export const workshopStateLockUrl = `${apiV1BaseUrl}/workshop-state/lock`;
export type WorkshopStateLockParameters = { workshopId: string, trainerId: string };
export type WorkshopStateLockResult = { success: true };

export const workshopStateSetBroadcastMessageUrl = `${apiV1BaseUrl}/workshop-state/set-broadcast-message`;
export type WorkshopStateSetBroadcastMessageBody = { workshopId: string, trainerId: string, text?: string };
export type WorkshopStateSetBroadcastMessageResult = { success: true };
