import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TstdlAngularModule } from '@tstdl/angular';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { ApplyingAppUpdateDialogComponent } from './components/applying-app-update-dialog/applying-app-update-dialog.component';
import { ButtonComponent } from './components/button/button.component';
import { CenterComponent } from './components/center/center.component';
import { CheckAppUpdateDialogComponent } from './components/check-app-update-dialog/check-app-update-dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { MessageBoxDialogComponent } from './components/message-box-dialog/message-box-dialog.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { SessionMismatchDialogComponent } from './components/session-mismatch-dialog/session-mismatch-dialog.component';
import { WaitForSyncDialogComponent } from './components/wait-for-sync-dialog/wait-for-sync-dialog.component';
import { BootstrapValidationDirective } from './directives/bootstrap-validation.directive';
import { QuestionBarComponent } from './pages/test/question-bar/question-bar.component';
import { ClozeAnswerComponent } from './pages/test/question/cloze-question/cloze-field/cloze-answer/cloze-answer.component';
import { ClozeFieldComponent } from './pages/test/question/cloze-question/cloze-field/cloze-field.component';
import { ClozeQuestionComponent } from './pages/test/question/cloze-question/cloze-question.component';
import { MultipleChoiceAnswerComponent } from './pages/test/question/multiple-choice-question/multiple-choice-answer/multiple-choice-answer.component';
import { MultipleChoiceQuestionComponent } from './pages/test/question/multiple-choice-question/multiple-choice-question.component';
import { QuestionComponent } from './pages/test/question/question.component';
import { ImageRenderPartComponent } from './pages/test/question/text-image/image-render-part.component';
import { TestComponent } from './pages/test/test.component';
import { PadPipe } from './pipes/pad.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

const exportTypes = [
  BootstrapValidationDirective,
  ButtonComponent,
  CenterComponent,
  DropdownComponent,
  FileSelectorComponent,
  NavbarComponent,
  PadPipe,
  ProgressbarComponent,
  QuestionBarComponent,
  TestComponent
];

const modules = [
  AngularMaterialModule,
  CommonModule,
  FormsModule,
  HttpClientModule,
  ReactiveFormsModule,
  TstdlAngularModule
];

@NgModule({
  declarations: [
    ApplyingAppUpdateDialogComponent,
    CheckAppUpdateDialogComponent,
    ClozeAnswerComponent,
    ClozeFieldComponent,
    ClozeQuestionComponent,
    FileSelectorComponent,
    ImageRenderPartComponent,
    LoadingDialogComponent,
    MessageBoxDialogComponent,
    MultipleChoiceAnswerComponent,
    MultipleChoiceQuestionComponent,
    QuestionBarComponent,
    QuestionComponent,
    SafeUrlPipe,
    SessionMismatchDialogComponent,
    WaitForSyncDialogComponent,
    ...exportTypes
  ],
  imports: modules,
  exports: [...exportTypes, ...modules]
})
export class CoreModule { }
