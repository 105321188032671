import { PartialProperty } from '@tstdl/base/types';
import { Entity as DatabaseEntity } from '@tstdl/base/database';

export type EntityMetadata<DataSource extends string = string, Data = any> = {
  readonly dataSource: DataSource,
  readonly data: Data
};

export type Entity = DatabaseEntity & {
  readonly metadata?: EntityMetadata
};

export type EntityWithPartialId<T extends Entity = Entity> = PartialProperty<T, 'id'>;
