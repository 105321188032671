import { NewEntity } from '@tstdl/base/database';
import { AnswerWithReference, ClozeAnswerWithReference, MultipleChoiceAnswerWithReference, NewAnswerWithReference, NewClozeAnswerWithReference, NewMultipleChoiceAnswerWithReference } from './answer';
import { Entity } from './entity';
import { Text, TextWithReference, TextWithReferenceWithoutId } from './text';

export enum QuestionType {
  MultipleChoice = 0,
  Cloze = 1
}

export type Question = Entity & {
  workshopId: string,
  type: QuestionType,
  testId: string,
  texts: Text[]
};

export type MultipleChoiceQuestion = Question & {
  type: QuestionType.MultipleChoice
};

export type ClozeQuestion = Question & {
  type: QuestionType.Cloze
};

export type NewQuestion = NewEntity<Question>;
export type NewMultipleChoiceQuestion = NewEntity<MultipleChoiceQuestion>;
export type NewClozeQuestion = NewEntity<ClozeQuestion>;

export type QuestionWithReference = Omit<Question, 'texts'> & { texts: TextWithReference[], answers: AnswerWithReference[] };
export type MultipleChoiceQuestionWithReference = Omit<MultipleChoiceQuestion, 'texts'> & { texts: TextWithReference[], answers: MultipleChoiceAnswerWithReference[] };
export type ClozeQuestionWithReference = Omit<ClozeQuestion, 'texts'> & { texts: TextWithReference[], answers: ClozeAnswerWithReference[] };

export type NewQuestionWithReference = Omit<NewQuestion, 'id' | 'workshopId' | 'texts' | 'testId'> & { texts: TextWithReferenceWithoutId[], answers: NewAnswerWithReference[] };
export type NewMultipleChoiceQuestionWithReference = Omit<NewMultipleChoiceQuestion, 'id' | 'workshopId' | 'texts' | 'testId'> & { texts: TextWithReferenceWithoutId[], answers: NewMultipleChoiceAnswerWithReference[] };
export type NewClozeQuestionWithReference = Omit<NewClozeQuestion, 'id' | 'workshopId' | 'texts' | 'testId'> & { texts: TextWithReferenceWithoutId[], answers: NewClozeAnswerWithReference[] };
