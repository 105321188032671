import { ErrorHandler, Injectable } from '@angular/core';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  private readonly notificationService: NotificationService;

  constructor(notificationService: NotificationService) {
    this.notificationService = notificationService;
  }

  handleError(error: Error): void {
    console.error({ error });

    let message: string = '';

    if (error instanceof Error) {
      message += `${error.name}: ${error.message}`;
    }

    if ((error as any).rejection instanceof Error) {
      const err = (error as any).rejection as Error;
      message = `${err.name}: ${err.message}`;
    }

    if ((error as any).error instanceof Error) {
      const err = (error as any).error as Error;
      message += `\n${err.name}: ${err.message}`;
    }

    if (message == '') {
      try {
        message = JSON.stringify(error, undefined, 2);
      }
      catch (stringifyError) {
        const err = (stringifyError as Error);
        message += `${err.name}: ${err.message}`;
      }
    }

    this.notificationService.error(message);
  }
}
