import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'trainer', loadChildren: () => import('./modules/trainer/trainer.module').then((m) => m.TrainerModule) },
  { path: '', loadChildren: () => import('./modules/participant/participant.module').then((m) => m.ParticipantModule) },
  { path: 'member', redirectTo: '', pathMatch: 'prefix' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
