import { ChangeDetectionStrategy, Component } from '@angular/core';
import { localizationKeys } from 'src/app/localization/localization-keys';

@Component({
  selector: 'app-check-app-update-dialog',
  templateUrl: './check-app-update-dialog.component.html',
  styleUrls: ['./check-app-update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckAppUpdateDialogComponent {
  readonly localizationKeys = localizationKeys;
}
