import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ImageWithReference } from 'src/app/common/model';

@Component({
  selector: 'app-image-render-part',
  templateUrl: './image-render-part.component.html',
  styleUrls: ['./image-render-part.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageRenderPartComponent implements OnDestroy, OnChanges {
  @Input() image: ImageWithReference;

  objectUrl: string;
  zoomed: boolean;

  constructor() {
    this.zoomed = false;
  }

  ngOnChanges(_changes: SimpleChanges): void {
    URL.revokeObjectURL(this.objectUrl);

    const blob = new Blob([this.image.file.data], { type: 'image/jpeg' });
    this.objectUrl = URL.createObjectURL(blob);
  }

  ngOnDestroy(): void {
    URL.revokeObjectURL(this.objectUrl);
  }
}
