import { CustomError } from '@tstdl/base/error';

export enum WorkshopImportErrorReason {
  InvalidPasswordOrCorruptFile = 0,
  UnknownUniqueNumber = 1,
  TestsNotGenerated = 2,
  WebServiceNotAvailable = 3,
  UnsupportedRevision = 4
}

export type WorkshopImportErrorData<T extends WorkshopImportErrorReason> =
  T extends WorkshopImportErrorReason.UnsupportedRevision
  ? { supportedRevision: number }
  : undefined;

export class WorkshopImportError<T extends WorkshopImportErrorReason> extends CustomError {
  static readonly errorName = 'WorkshopImportError';

  reason: T;
  data: WorkshopImportErrorData<T>;

  constructor(reason: T, data: WorkshopImportErrorData<T>) {
    super({ message: 'workshop import failed' });

    this.reason = reason;
    this.data = data;
  }
}
