import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DeepReadonly } from '@tstdl/base/types';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ClozeAnswerWithReference, ClozeQuestionRecord, ClozeQuestionWithReference, Language } from 'src/app/common/model';
import { RenderPart, TextService } from 'src/app/modules/core/services/text.service';

@Component({
  selector: 'app-cloze-question',
  templateUrl: './cloze-question.component.html',
  styleUrls: ['./cloze-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClozeQuestionComponent implements OnInit, OnChanges {
  private readonly textService: TextService;
  private readonly questionSubject: Subject<ClozeQuestionWithReference>;
  private readonly languageSubject: Subject<Language | undefined>;

  @Input() question: ClozeQuestionWithReference;
  @Input() record: ClozeQuestionRecord;
  @Input() language: Language | undefined;
  @Input() readonly: boolean;

  @Output() readonly recordChange: EventEmitter<ClozeQuestionRecord>;

  renderParts$: Observable<RenderPart[]>;

  constructor(textService: TextService) {
    this.textService = textService;

    this.questionSubject = new ReplaySubject(1);
    this.languageSubject = new ReplaySubject(1);
    this.recordChange = new EventEmitter();
    this.readonly = false;
  }

  ngOnInit(): void {
    this.renderParts$ = this.questionSubject.pipe(
      switchMap((question) => this.textService.getRenderParts(this.languageSubject.asObservable(), question.texts, question.answers))
    );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.questionSubject.next(this.question);
    this.languageSubject.next(this.language);
  }

  getSelectedAnswer(clozeIndex: number): ClozeAnswerWithReference | undefined {
    if (this.readonly) {
      return undefined;
    }

    const selectedAnswerId = this.record.selectedAnswerIds[clozeIndex];
    const selectedAnswer = this.question.answers.find((answer) => answer.id == selectedAnswerId);

    return selectedAnswer;
  }

  answerSelected(answer: ClozeAnswerWithReference): void {
    if (this.readonly) {
      return;
    }

    const updatedRecord = selectAnswer(this.record, answer);
    this.recordChange.emit(updatedRecord);
  }

  removeSelection(clozeIndex: number): void {
    if (this.readonly) {
      return;
    }

    const updatedRecord = removeSelection(this.record, clozeIndex);
    this.recordChange.emit(updatedRecord);
  }
}

function removeSelection(record: DeepReadonly<ClozeQuestionRecord>, clozeIndex: number): ClozeQuestionRecord {
  const { [clozeIndex]: _removed, ...selectedAnswerIdsRest } = record.selectedAnswerIds;

  const updatedRecord: ClozeQuestionRecord = {
    ...record,
    selectedAnswerIds: selectedAnswerIdsRest
  };

  return updatedRecord;
}

function selectAnswer(record: DeepReadonly<ClozeQuestionRecord>, answer: ClozeAnswerWithReference): ClozeQuestionRecord {
  const updatedRecord: ClozeQuestionRecord = {
    ...record,
    selectedAnswerIds: {
      ...record.selectedAnswerIds,
      [answer.clozeIndex]: answer.id
    }
  };

  return updatedRecord;
}
