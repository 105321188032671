import { Enumerable } from '@tstdl/base/enumerable';
import { intersectSets } from '@tstdl/base/utils';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getLanguage } from './languages';
import { ClozeQuestionWithReference, DashboardDataTestRecordItem, DocumentWithReference, FeedbackWithReference, Language, QuestionRecord, QuestionType, QuestionWithReference, TestRecord, TestWithReference } from './model';

export function getQuestions$(test$: Observable<TestWithReference>): Observable<QuestionWithReference[]> {
  return test$.pipe(
    map((test) => test.questions)
  );
}

export function getUnansweredQuestions$(questions$: Observable<QuestionWithReference[]>, testRecord$: Observable<TestRecord>): Observable<QuestionWithReference[]> {
  return combineLatest([questions$, testRecord$]).pipe(
    map(([questions, record]) => getUnansweredQuestions(questions, record))
  );
}

export function getAnsweredQuestions$(questions$: Observable<QuestionWithReference[]>, testRecord$: Observable<TestRecord>): Observable<QuestionWithReference[]> {
  return combineLatest([questions$, testRecord$]).pipe(
    map(([questions, record]) => getAnsweredQuestions(questions, record))
  );
}

export function getMarkedQuestions$(questions$: Observable<QuestionWithReference[]>, testRecord$: Observable<TestRecord>): Observable<QuestionWithReference[]> {
  return combineLatest([questions$, testRecord$]).pipe(
    map(([questions, record]) => getMarkedQuestions(questions, record))
  );
}

export function getTimeSpentString$(testRecord$: Observable<TestRecord | DashboardDataTestRecordItem>): Observable<string> {
  return testRecord$.pipe(
    map(getTimeSpentString)
  );
}

export function getArrayLength$(array$: Observable<any[]>): Observable<number> {
  return array$.pipe(
    map((items) => items.length)
  );
}

export function arrayHasLength$(array$: Observable<any[]>, length$: Observable<number>): Observable<boolean> {
  return combineLatest([array$, length$]).pipe(
    map(([array, length]) => array.length == length)
  );
}

export function getMaximumTestPoints(test: TestWithReference): number {
  const points = test.questions.flatMap((question) => question.answers).reduce((sum, answer) => answer.isCorrect ? sum + 1 : sum, 0);
  return points;
}

export function getUnansweredQuestions<Q extends QuestionWithReference>(questions: Q[], testRecord: TestRecord): Q[] {
  return questions.filter((question) => !resultHasAnswer(question, testRecord.questionRecords[question.id]));
}

export function getAnsweredQuestions<Q extends QuestionWithReference>(questions: Q[], testRecord: TestRecord): Q[] {
  return questions.filter((question) => resultHasAnswer(question, testRecord.questionRecords[question.id]));
}

export function getMarkedQuestions<Q extends QuestionWithReference>(questions: Q[], testRecord: TestRecord): Q[] {
  return questions.filter((question) => testRecord.markedQuestionIds.includes(question.id));
}

export function getTimeSpentString(record: TestRecord | DashboardDataTestRecordItem): string {
  if (!record.started) {
    return '-';
  }

  const minutesString = Math.floor(record.millisecondsSpent / 1000 / 60).toString();
  return `${minutesString} min`;
}

export function getTestLanguages(test: TestWithReference): Language[] {
  if (test.questions.length == 0) {
    return [];
  }

  const languages = Enumerable.from(test.questions[0]!.texts)
    .map((text) => text.languageCode)
    .map((code) => getLanguage(code))
    .toArray();

  return languages;
}

export function getFeedbackLanguages(feedback?: FeedbackWithReference): Language[] | undefined {
  if (feedback == undefined) {
    return undefined;
  }

  const languages = feedback.questions[0]!.texts.map((text) => getLanguage(text.languageCode));
  return languages;
}

export function getCommonTermsLanguages(documents: DocumentWithReference[]): Language[] | undefined {
  if (documents.length == 0) {
    return undefined;
  }

  const sets: Set<Language>[] = [];

  for (const document of documents) {
    const termsSet = new Set<Language>();

    if (document.terms.length == 0) {
      continue;
    }

    for (const term of document.terms) {
      const language = getLanguage(term.languageCode);
      if (language != undefined) {
        termsSet.add(language);
      }
    }

    sets.push(termsSet);
  }

  const languages = [...intersectSets(...sets)];
  return languages;
}

function resultHasAnswer(question: QuestionWithReference, result: QuestionRecord | undefined): boolean {
  if (result == undefined) {
    return false;
  }

  switch (question.type) {
    case QuestionType.MultipleChoice:
      return Number.parseInt(result.selectedAnswerIds.length as any) > 0;

    case QuestionType.Cloze:
      const clozeCount = new Set((question as ClozeQuestionWithReference).answers.map((answer) => answer.clozeIndex)).size;
      return Object.values(result.selectedAnswerIds).length == clozeCount;

    default:
      throw new Error('QuestionResultType not supported');
  }
}
