import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStabilizationService } from '@tstdl/angular';
import { isErrorResponse, isResultResponse, parseErrorResponse, Response } from '@tstdl/base/api';
import { DetailsError } from '@tstdl/base/error';
import { StringMap, UndefinableJson } from '@tstdl/base/types';
import { FactoryMap, timeout } from '@tstdl/base/utils';
import { EMPTY, firstValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { PingResult, pingUrl } from 'src/app/common/api';

export enum CacheType {
  None,
  Fallback,
  Full
}

export enum RequestState {
  Upload,
  Sent,
  Download,
  Done
}

export type RequestEvent<T> =
  | {
    done: false,
    state: RequestState.Upload | RequestState.Download,
    progress?: number,
    loaded: number,
    total?: number
  }
  | {
    done: false,
    state: RequestState.Sent,
  }
  | {
    done: true,
    state: RequestState.Done,
    result: T
  };

export type Parameters = StringMap<string>;
export type Body = UndefinableJson;

const urlParseRegex = /([\/\w-]+)|(?::(\w+))/gi;

function parseUrl(url: string, parameters?: Parameters): { resultUrl: string, parametersRest: Parameters } {
  const matches = url.matchAll(urlParseRegex);

  let resultUrl = '';
  let parametersRest = parameters != undefined ? parameters : {};

  for (const [match, path, parameter] of matches) {
    if (path != undefined) {
      resultUrl += path;
    }
    else if (parameter != undefined) {
      if (!parametersRest.hasOwnProperty(parameter)) {
        throw new Error(`parameter ${parameter} not found`);
      }

      const { [parameter]: value, ...rest } = parametersRest;
      parametersRest = rest;
      resultUrl += value;
    }
    else {
      throw new Error("something's wrong");
    }
  }

  return { resultUrl, parametersRest };
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly appStabilizationService: AppStabilizationService;
  private readonly httpClient: HttpClient;
  private readonly cache: FactoryMap<string, Map<string, any>>;

  readonly connectionAvailable$: Observable<boolean>;

  constructor(appStabilizationService: AppStabilizationService, httpClient: HttpClient) {
    this.appStabilizationService = appStabilizationService;
    this.httpClient = httpClient;

    this.cache = new FactoryMap(() => new Map());
    this.connectionAvailable$ = this.getConnectionAvailableObservable();
  }

  private getConnectionAvailableObservable(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      let unsubscribed = false;

      (async () => {
        await this.appStabilizationService.wait();

        while (!unsubscribed) {
          try {
            const success = await firstValueFrom(this.ping());
            subscriber.next(success);
          }
          catch (error) {
            console.error(error);
            subscriber.next(false);
          }

          await timeout(5000);
        }
      })();

      return () => unsubscribed = true;
    }).pipe(
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  clearCache(url: string): void {
    this.cache.delete(url);
  }

  ping(): Observable<boolean> {
    const { resultUrl } = parseUrl(pingUrl);
    return this.httpClient.get<Response<PingResult>>(resultUrl, { observe: 'response' })
      .pipe(
        map(parseResponse),
        map((response) => response.success),
        catchError(() => of(false))
      );
  }

  get<TResult>(url: string, cacheType?: CacheType): Observable<TResult>;
  get<TParameters extends Parameters, TResult>(url: string, parameters: TParameters, cacheType?: CacheType): Observable<TResult>;
  get<TParameters extends Parameters, TResult>(url: string, parametersOrCacheType?: TParameters | CacheType, cacheType: CacheType = CacheType.None): Observable<TResult> {
    const parameters = typeof parametersOrCacheType == 'object' ? parametersOrCacheType : undefined;
    if (typeof parametersOrCacheType == 'number') {
      cacheType = parametersOrCacheType;
    }

    const cache = this.cache.get(url);
    const cacheKey = getCacheKey('get', parameters, undefined, cacheType);

    if (cacheType == CacheType.Full && cache.has(cacheKey as string)) {
      const cachedResult = cache.get(cacheKey as string) as TResult;
      return of(cachedResult);
    }

    const { resultUrl, parametersRest } = parseUrl(url, parameters);
    const params = new HttpParams({ fromObject: parametersRest });

    return this.httpClient.get<Response<TResult>>(resultUrl, { params, observe: 'response' }).pipe(
      map(parseResponse),
      catchError((error, caught) => catchErrorReturnCache(error as Error, caught, cache, cacheType, cacheKey)),
      catchError(handleError),
      tap((result) => {
        if (cacheType != CacheType.None) {
          cache.set(cacheKey as string, result);
        }
      })
    );
  }

  post<TBody extends Body, TResult>(url: string, body: TBody, cacheType?: CacheType): Observable<TResult>;
  post<TParameters extends Parameters, TBody extends Body, TResult>(url: string, body: TBody, parameters: TParameters, cacheType?: CacheType): Observable<TResult>;
  post<TParameters extends Parameters, TBody extends Body, TResult>(url: string, body: TBody, parametersOrCacheType?: TParameters | CacheType, cacheType: CacheType = CacheType.None): Observable<TResult> {
    const parameters = typeof parametersOrCacheType == 'object' ? parametersOrCacheType : undefined;
    if (typeof parametersOrCacheType == 'number') {
      cacheType = parametersOrCacheType;
    }

    const cache = this.cache.get(url);
    const cacheKey = getCacheKey('post', parameters, body, cacheType);

    if (cacheType == CacheType.Full && cache.has(cacheKey as string)) {
      const cachedResult = cache.get(cacheKey as string) as TResult;
      return of(cachedResult);
    }

    const { resultUrl, parametersRest } = parseUrl(url, parameters);
    const params = new HttpParams({ fromObject: parametersRest });

    return this.httpClient.post<Response<TResult>>(resultUrl, body, { params, observe: 'response' }).pipe(
      map(parseResponse),
      catchError((error, caught) => catchErrorReturnCache(error as Error, caught, cache, cacheType, cacheKey)),
      catchError(handleError),
      tap((result) => {
        if (cacheType != CacheType.None) {
          cache.set(cacheKey as string, result);
        }
      })
    );
  }

  getWithProgress<TResult>(url: string): Observable<RequestEvent<TResult>>;
  getWithProgress<TParameters extends Parameters, TResult>(url: string, parameters: TParameters): Observable<RequestEvent<TResult>>;
  getWithProgress<TParameters extends Parameters, TResult>(url: string, parameters?: TParameters): Observable<RequestEvent<TResult>> {
    const { resultUrl, parametersRest } = parseUrl(url, parameters);
    const params = new HttpParams({ fromObject: parametersRest });
    const request = new HttpRequest<void>('GET', resultUrl, { params, reportProgress: true });

    return this.httpClient.request<Response<TResult>>(request).pipe(
      switchMap(httpEventToRequestEvent),
      catchError(handleError)
    );
  }

  postWithProgress<TBody extends Body, TResult>(url: string, body: TBody): Observable<RequestEvent<TResult>>;
  postWithProgress<TParameters extends Parameters, TBody extends Body, TResult>(url: string, body: TBody, parameters: TParameters): Observable<RequestEvent<TResult>>;
  postWithProgress<TParameters extends Parameters, TBody extends Body, TResult>(url: string, body: TBody, parameters?: TParameters): Observable<RequestEvent<TResult>> {
    const { resultUrl, parametersRest } = parseUrl(url, parameters);
    const params = new HttpParams({ fromObject: parametersRest });
    const request = new HttpRequest('POST', resultUrl, body, { params, reportProgress: true });

    return this.httpClient.request<Response<TResult>>(request).pipe(
      switchMap(httpEventToRequestEvent),
      catchError(handleError)
    );
  }
}

function getCacheKey(type: string, parameters: UndefinableJson | undefined, body: UndefinableJson | undefined, cacheType: CacheType): string | undefined {
  return (cacheType == CacheType.None) ? undefined : JSON.stringify({ type, body, parameters });
}

function catchErrorReturnCache<T>(error: Error, _caught: Observable<T>, cache: Map<string, any>, cacheType: CacheType, cacheKey: string | undefined): Observable<T> {
  if (cacheType == CacheType.Fallback && cacheKey != undefined && cache.has(cacheKey)) {
    const cached = cache.get(cacheKey) as T;
    return of(cached);
  }

  return throwError(error);
}

function handleError<T>(response: HttpErrorResponse, _caught: Observable<T>): Observable<T> {
  const { error } = response;

  if (response.status >= 100 && isResultResponse(error)) {
    return of(error.result as T);
  }

  let _error: Error;

  if (error instanceof Error) {
    _error = error;
  }
  else if (error instanceof ErrorEvent) {
    _error = new Error(error.message);
  }
  else if (isErrorResponse(error)) {
    _error = parseErrorResponse(error);
  }
  else if (typeof error == 'string') {
    _error = new Error(error);
  }
  else {
    _error = new DetailsError('error, details attached', error);
  }

  return throwError(_error);
}

function parseResponse<T>(response: HttpResponse<Response<T>>): T {
  if (isResultResponse(response.body)) {
    return response.body.result;
  }

  if (isErrorResponse(response.body)) {
    throw parseErrorResponse(response.body);
  }

  if (response.ok) {
    throw new Error('unsupported response format');
  }

  throw new Error(`${response.status} - ${response.statusText}`);
}

function httpEventToRequestEvent<T>(event: HttpEvent<Response<T>>): Observable<RequestEvent<T>> {
  let progressEvent: RequestEvent<T> | undefined;

  switch (event.type) {
    case HttpEventType.DownloadProgress:
      progressEvent = {
        done: false,
        state: RequestState.Download,
        progress: (event.total != undefined) ? (event.loaded / event.total) : undefined,
        loaded: event.loaded,
        total: event.total
      };

      break;

    case HttpEventType.UploadProgress:
      progressEvent = {
        done: false,
        state: RequestState.Upload,
        progress: (event.total != undefined) ? (event.loaded / event.total) : undefined,
        loaded: event.loaded,
        total: event.total
      };

      break;

    case HttpEventType.Response:
      const result = parseResponse(event);
      progressEvent = { done: true, state: RequestState.Done, result };

      break;

    case HttpEventType.Sent:
      progressEvent = {
        done: false,
        state: RequestState.Sent
      };

      break;

    case HttpEventType.ResponseHeader:
    case HttpEventType.User:
      return EMPTY;

    default:
      throw new Error('unsupported event');
  }

  return of(progressEvent!);
}
