<ul class="pagination">
  <ng-container *ngFor="let question of questions; let index = index; trackBy: trackByQuestion">
    <li *ngIf="!isHidden(question)" class="page-item"
        [class.active]="selectedQuestion == question"
        [class.unanswered]="isUnanswered(question)"
        [class.answered]="isAnswered(question)"
        [class.marked]="isMarked(question)">
      <a class="page-link" (click)="questionClicked(question)">
        {{ (index + 1) | pad:2:0 }}
      </a>
    </li>
  </ng-container>
</ul>
