import { languages } from '../../common/languages';
import { EtestOnlineLocalization } from '../localization';

export const german: EtestOnlineLocalization = {
  language: languages.de,
  keys: {
    address_type: 'Addresstyp',
    agenda_upload_dialog_description: 'Dieser Workshop erfordert eine CWS-Agenda. Laden Sie die Agenda bitte als PDF hoch.',
    agenda_upload_dialog_header: 'Agenda hochladen',
    agenda_upload_dialog_select_file: 'Klicken um eine Datei auszuwählen',
    agree_document: 'Akzeptieren',
    agree_documents_before_continue: 'Um fortzufahren, müssen Sie alle Bedingungen akzeptieren',
    already_registered: 'Bereits registriert',
    answered_questions: 'Beantwortete Fragen',
    auto_redirect: 'Sie werden automatisch weitergeleitet',
    back: 'Zurück',
    begin: 'Beginn',
    both: 'Beides',
    broadcast_message: 'Mitteilung an Teilnehmer',
    business_address: 'Geschäftlich',
    cancel: 'Abbrechen',
    city: 'Stadt',
    command_failed: 'Befehl fehlgeschlagen',
    command_sent: 'Befehl an Server gesendet',
    company: 'Firma',
    connection_required: 'Verbindung benötigt',
    contact_trainer: 'Wenden Sie sich an ihren Trainer',
    continue: 'Weiter',
    controls: 'Steuerung',
    country: 'Land',
    delete_member: 'Teilnehmer entfernen',
    delete_participant_confirm: 'Möchten Sie Teilnehmer {{ firstName }} {{ lastName }} wirklich löschen?',
    delete_participant: 'Teilnehmer löschen',
    delete_workshop_confirm: 'Möchten Sie den Workshop wirklich unwiderruflich löschen?',
    delete_workshop: 'Workshop löschen',
    delete: 'Löschen',
    do_submit_and_end_workshop: 'Ja, abgeben und Workshop beenden',
    do_submit: 'Ja, abgeben und zurück zur Testauswahl',
    documents: 'Dokumente',
    dont_submit: 'Noch nicht',
    download_results_failed_message: 'Beim Exportieren der Tests ist ein Fehler aufgetreten.',
    download_results_message_text: 'Der Download startet automatisch sobald der Export generiert wurde. Dies kann bis zu einer Minute dauern.',
    download_results: 'Ergebnisse herunterladen',
    download_workshop: 'Workshop herunterladen',
    duration: 'Dauer',
    end_workshop_and_submit_feedback: 'Feedback abgeben und Workshop beenden',
    end_workshop_without_feedback: 'Workshop ohne Feedback beenden',
    end_workshop: 'Workshop beenden',
    end: 'Ende',
    error_no_connection: 'Keine Verbindung.',
    error_tests_not_generated: 'Tests wurden noch nicht generiert. Download im eTest-Backend erforderlich.',
    error_unknown_workshop: 'Unbekannter Workshop',
    error_unknown: 'Unbekannter Fehler',
    error_webservice_not_available: 'Webservice nicht erreichbar',
    error_workshop_locked: 'Login ist gesperrt. Kontaktieren Sie Ihren Trainer.',
    checking_app_update_dialog_header: 'Überprüfe auf Aktualisierung',
    applying_app_update_dialog_header: 'Aktualisiere Applikation',
    unsupported_revision_message_box_title: 'Nicht unterstützte Import-Datei',
    unsupported_revision_message_box_message: 'Revision der Import-Datei wird nicht unterstützt, bitte laden Sie Revision {{ supportedRevision }} im eTest Backend herunter.',
    application_update_available_message_box_title: 'Aktualisierung verfügbar',
    application_update_available_message_box_message: 'Für diese Applikation ist eine Aktualisierung verfügbar. Seite wird neugeladen werden.',
    exit: 'Beenden',
    export_workshop: 'Workshop exportieren',
    export: 'Export',
    feedback_comment: 'Haben Sie weitere konkrete Verbesserungsvorschläge?',
    feedback_instruction: 'Eine Bewertung von 1 bedeutet, dass die Aussage voll zutrifft, während 5 bedeutet, dass es überhaupt nicht zutrifft.',
    feedback_language_hint: 'Hinweis: Sie können die Sprache des Feedbacks über das das Menü "Language" -> "Feedback language" ändern.',
    feedback_question_text: 'Text',
    feedback_rating: 'Bewertung',
    feedback_thanks: 'Vielen Dank für das Ausfüllen des Feedbacks.',
    feedback: 'Feedback',
    fetch_from_backend: 'Vom Backend abrufen',
    file: 'Datei',
    first_name: 'Vorname',
    fullscreen: 'Vollbild',
    give_feedback_question: 'Möchten Sie ein Feedback abgegeben?',
    how_to_export: 'Wie möchten Sie den Workshop exportieren?',
    incomplete: 'unvollständig',
    internet_explorer_warning: 'Internet Explorer wird nicht unterstützt und kann zu Problemen führen. Es wird empfohlen, Chrome oder Firefox zu verwenden.',
    invalid_country: 'Ungültiges Land',
    invalid_file_or_wrong_password: 'Hochgeladene Datei ist fehlerhaft oder falsches Passwort angegeben',
    language: 'Sprache',
    last_name: 'Nachname',
    loading_documents: 'Dokumente werden geladen',
    loading_test: 'Test wird geladen...',
    lock_workshop: 'Teilnehmer-Anmeldung sperren',
    logout: 'Abmelden',
    mail: 'E-Mail',
    mark_question: 'Frage markieren',
    marked_questions: 'Markierte Fragen',
    marked: 'Markiert',
    member_documents_info: 'Lesen Sie die Dokumente und akzeptieren Sie sie um fortzufahren',
    member_welcome_1: 'Herzlich willkommen zum eTest Online',
    member_welcome_2: 'Um zu den Tests zu gelangen, füllen Sie bitte das Formular aus und klicken Sie auf Weiter',
    member: 'Teilnehmer',
    minutes_abbreviation: 'min',
    mr: 'Herr',
    ms: 'Frau',
    no_participants: 'Es sind noch keine Teilnehmer angemeldet.',
    no: 'Nein',
    ok: 'Ok',
    optional_document: 'optional',
    participants: 'Teilnehmer',
    participate: 'Teilnehmen',
    password: 'Passwort',
    period: 'Zeitraum',
    phone_number: 'Telefonnummer',
    please_wait: 'Bitte warten',
    postal_code: 'Postleitzahl',
    private_address: 'Privat',
    progress: 'Fortschritt',
    rating_1: 'trifft voll zu',
    rating_2: 'trifft zu',
    rating_3: 'trifft nicht zu',
    rating_4: 'trifft garnicht zu',
    record_session_mismatch_prompt_description: 'Der Test "{{ testName }}" wurde von einem anderen Gerät aus fortgesetzt. Möchten Sie den aktuell Stand auf diesem Gerät behalten und weiterverwenden oder verwerfen und den anderen Test fortsetzen?',
    record_session_mismatch_prompt_header: 'Test Konflikt',
    record_session_mismatch_prompt_use_curret: 'Weiterverwenden',
    record_session_mismatch_prompt_use_remote: 'Verwerfen',
    release_workshop: 'Teilnehmer-Anmeldung freigeben',
    request_help: 'Hilfe anfordern',
    requested_help: 'Hilfe',
    return_to_test_selection: 'Zurück zur Testauswahl',
    send: 'Senden',
    set_broadcast_message_dialog_header: 'Mitteilung an Teilnehmer',
    shutdown_device: 'Server herunterfahren',
    street: 'Straße',
    submission_confirmation_dialog_answers_cannot_be_changed: 'Nach der Abgabe können die Antworten nicht mehr geändert werden!',
    submission_confirmation_dialog_header: 'Sind Sie sicher?',
    submission_confirmation_dialog_open_tests: 'Verbleibende Tests: {{ openTestsCount }}',
    submit_test: 'Test abgeben',
    submit_workshop: 'Workshop übertragen',
    submitted: 'Abgegeben',
    terms_language_hint: 'Hinweis: Die Sprache der Bedingungen kann über das Menü "Language" -> "Terms language" geändert werden.',
    test: 'Test',
    tests: 'Tests',
    thank_you_for_participation: 'Vielen Dank für Ihre Teilnahme!',
    time_spent: 'Vergangene Zeit',
    title: 'Anrede',
    trainer_welcome: 'Bitte authentifizieren Sie sich um fortzufahren',
    trainer: 'Trainer',
    transfer_results_to_backend: 'Ergebnisse ins Backend übertragen',
    transfer_success: 'Übertragung der Ergebnisse abgeschlossen',
    transferring_results: 'Ergebnisse werden übertragen...',
    try_later_or_use_file_upload: 'Versuchen Sie es später noch einmal oder nutzen Sie den Datei-Upload',
    unanswered_questions: 'Unbeantwortete Fragen',
    unique_number: 'Unikatsnummer',
    unmark_question: 'Markierung entfernen',
    unsupported_filetype: 'Dateityp nicht unterstützt',
    unsynchronized_warning: 'Achtung: Unsynchronisierte Änderungen. Das Fenster nicht schließen.',
    upload_agenda: 'Agenda hochladen',
    upload_file: 'Datei hochladen',
    upload: 'Hochladen',
    wait_for_sync_description: 'Auf Synchronisierung von ausstehenden Änderungen wird gewartet...',
    wait_for_sync_header: 'Synchronisierung',
    without_answer: 'Ohne Antwort',
    workshop_country: 'Workshop Land',
    workshop_importing: 'Workshop wird importiert...',
    workshop_not_available_import_needed: 'Workshop noch nicht verfügbar und muss importiert werden, wie möchten Sie ihn importieren?',
    yes: 'Ja',
    zoom: 'Zoom'
  }
};
