import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output, Renderer2, SimpleChanges, OnInit } from '@angular/core';

@Component({
  selector: '[app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit, OnChanges {
  private readonly elementRef: ElementRef;
  private readonly renderer: Renderer2;

  private lastStyleClass: string;

  @Input() disabled: boolean;
  @Input() color: string;
  @Input() size: string;
  @Input() outline: boolean;
  @Input() loading: boolean;
  @Input() active: boolean;
  @HostBinding('attr.type') @Input() type: string;

  @Output() readonly buttonClick: EventEmitter<void>;

  @HostBinding('attr.disabled')
  get disabledAttribute(): boolean | undefined {
    return this.disabled ? true : undefined;
  }

  private get btnStyleClass(): string {
    let btnClass = 'btn';

    if (this.outline) {
      btnClass += '-outline';
    }

    btnClass += `-${this.color}`;

    return btnClass;
  }

  constructor(elementRef: ElementRef, renderer: Renderer2) {
    this.elementRef = elementRef;
    this.renderer = renderer;

    this.buttonClick = new EventEmitter();

    this.type = 'button';
    this.color = 'primary';
    this.size = 'normal';
    this.outline = false;
    this.active = false;
    this.disabled = false;
    this.loading = false;
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.updateClasses();
  }

  private updateClasses(): void {
    const styleClass = this.btnStyleClass;

    if (this.lastStyleClass != styleClass) {
      this.setClass(this.lastStyleClass, false);
      this.lastStyleClass = styleClass;
    }

    this.setClass('btn', true);
    this.setClass(this.btnStyleClass, true);
    this.setClass('active', this.active);
    this.setClass('btn-sm', this.size == 'small');
    this.setClass('btn-lg', this.size == 'large');
  }

  @HostListener('click')
  onClick(): void {
    if (this.disabled) {
      return;
    }

    this.buttonClick.emit();
  }

  private setClass(className: string, set: boolean): void {
    if (set) {
      this.renderer.addClass(this.elementRef.nativeElement, className);
    }
    else {
      this.renderer.removeClass(this.elementRef.nativeElement, className);
    }
  }
}
