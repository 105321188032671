import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionWithReference } from 'src/app/common/model';

@Component({
  selector: 'app-question-bar',
  templateUrl: './question-bar.component.html',
  styleUrls: ['./question-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionBarComponent implements OnInit {
  @Input() selectedQuestion: QuestionWithReference | undefined;
  @Input() questions: QuestionWithReference[];
  @Input() unansweredQuestions: QuestionWithReference[];
  @Input() answeredQuestions: QuestionWithReference[];
  @Input() markedQuestions: QuestionWithReference[];
  @Input() hiddenQuestions: QuestionWithReference[];

  @Output() readonly questionSelected: EventEmitter<QuestionWithReference>;

  constructor() {
    this.selectedQuestion = undefined;
    this.unansweredQuestions = [];
    this.answeredQuestions = [];
    this.markedQuestions = [];
    this.hiddenQuestions = [];

    this.questionSelected = new EventEmitter();
  }

  ngOnInit(): void {
    if (this.questions == undefined) {
      throw new Error('no questions set');
    }

    if (this.questions.length == 0) {
      throw new Error('empty array');
    }
  }

  isUnanswered(question: QuestionWithReference): boolean {
    const isUnanswered = this.unansweredQuestions.some((unansweredQuestion) => unansweredQuestion.id == question.id);
    return isUnanswered;
  }

  isAnswered(question: QuestionWithReference): boolean {
    const isAnswered = this.answeredQuestions.some((answeredQuestion) => answeredQuestion.id == question.id);
    return isAnswered;
  }

  isMarked(question: QuestionWithReference): boolean {
    const isMarked = this.markedQuestions.some((markedQuestion) => markedQuestion.id == question.id);
    return isMarked;
  }

  isHidden(question: QuestionWithReference): boolean {
    const isHidden = this.hiddenQuestions.some((hiddenQuestion) => hiddenQuestion.id == question.id);
    return isHidden;
  }

  questionClicked(question: QuestionWithReference): void {
    this.questionSelected.next(question);
  }

  trackByQuestion(_index: number, question: QuestionWithReference): string {
    return question.id;
  }
}
