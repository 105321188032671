import { Injectable, NgZone } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LocalizationData, LocalizationService } from '@tstdl/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly snackBar: MatSnackBar;
  private readonly localizationService: LocalizationService;
  private readonly zone: NgZone;
  private readonly queue: string[];

  constructor(snackBar: MatSnackBar, localizationService: LocalizationService, zone: NgZone) {
    this.snackBar = snackBar;
    this.localizationService = localizationService;
    this.zone = zone;

    this.queue = [];
  }

  localizedInfo(message: LocalizationData): void {
    const localizedMessage = this.localizationService.localize(message);
    this.info(localizedMessage);
  }

  info(message: string): void {
    this.queue.push(message);
    this.handle();
  }

  localizedError(message: LocalizationData): void {
    const localizedMessage = this.localizationService.localize(message);
    this.error(localizedMessage);
  }

  error(message: string): void {
    this.info(message);
  }

  private handle(): void {
    if (this.snackBar._openedSnackBarRef == undefined) {
      this.showNext();
    }
  }

  private showNext(): void {
    const message = this.queue.shift();

    if (message == undefined) {
      return;
    }

    this.zone.run(() => {
      const snackBarRef = this.snackBar.open(message, 'Ok', { duration: 5000 });
      snackBarRef.afterDismissed().subscribe(() => this.showNext());
    });
  }
}
