import { CustomError } from '@tstdl/base/error';

export enum RegisterErrorReason {
  AlreadyRegistered,
  DocumentCheckRequired
}

export class RegisterError extends CustomError {
  static readonly errorName = 'RegisterError';

  reason: RegisterErrorReason;

  constructor(reason: RegisterErrorReason) {
    super({ message: 'registration error' });

    this.reason = reason;
  }
}
