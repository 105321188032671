<app-navbar [uiLanguages]="uiLanguages"
            [testLanguages]="testLanguages$ | async"
            [feedbackLanguages]="feedbackLanguages$ | async"
            [termsLanguages]="termsLanguages$ | async"
            [isLoggedIn]="isLoggedIn"
            (uiLanguageSelected)="setUiLanguage($event)"
            (testLanguageSelected)="setTestLanguage($event)"
            (feedbackLanguageSelected)="setFeedbackLanguage($event)"
            (termsLanguageSelected)="setTermsLanguage($event)"
            (logout)="onLogout()"
            (toggleFullscreen)="onToggleFullscreen()"></app-navbar>

<div class="container content content-grid">
  <div *ngIf="!(apiService.connectionAvailable$ | async) || (testRecordService.pendingAge$ | async)! > 11000"
       class="content-grid-ignore alert"
       [class.alert-warning]="(testRecordService.pendingCount$ | async)! == 0"
       [class.alert-danger]="(testRecordService.pendingCount$ | async)! > 0">
    <div *ngIf="!(apiService.connectionAvailable$ | async)">{{ localizationKeys.error_no_connection | localize }}</div>
    <div *ngIf="(testRecordService.pendingCount$ | async)! > 0">{{ localizationKeys.unsynchronized_warning | localize }} ({{ (pendingAgeSeconds$ | async) }}s)</div>
  </div>

  <router-outlet></router-outlet>
</div>
