import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { HelpRequestGetStateParameters, HelpRequestGetStateResult, helpRequestGetStateUrl, HelpRequestSetStateBody, HelpRequestSetStateResult, helpRequestSetStateUrl } from 'src/app/common/api';
import { ApiService, CacheType } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class HelpRequestService {
  private readonly apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  setState({ participantId, workshopId, state }: HelpRequestSetStateBody): Observable<HelpRequestSetStateResult> {
    return this.apiService.post<HelpRequestSetStateBody, HelpRequestSetStateResult>(helpRequestSetStateUrl, { participantId, workshopId, state });
  }

  getState({ participantId }: HelpRequestGetStateParameters): Observable<HelpRequestGetStateResult> {
    return this.apiService.get<HelpRequestGetStateParameters, HelpRequestGetStateResult>(helpRequestGetStateUrl, { participantId }, CacheType.Fallback)
      .pipe(
        timeout(1000),
        catchError(() => of({ state: false }))
      );
  }
}
