import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Language, MultipleChoiceAnswerWithReference } from 'src/app/common/model';
import { RenderPart, TextService } from 'src/app/modules/core/services/text.service';

@Component({
  selector: '[app-multiple-choice-answer]',
  templateUrl: './multiple-choice-answer.component.html',
  styleUrls: ['./multiple-choice-answer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleChoiceAnswerComponent implements OnInit, OnChanges {
  private readonly textService: TextService;
  private readonly answerSubject: Subject<MultipleChoiceAnswerWithReference>;
  private readonly languageSubject: Subject<Language | undefined>;

  @Input() answer: MultipleChoiceAnswerWithReference;
  @Input() selected: boolean;
  @Input() language: Language | undefined;
  @Input() readonly: boolean;

  @Output() readonly toggle: EventEmitter<void>;

  renderParts$: Observable<RenderPart[]>;

  constructor(textService: TextService) {
    this.textService = textService;

    this.answerSubject = new ReplaySubject(1);
    this.languageSubject = new ReplaySubject(1);
    this.toggle = new EventEmitter();
    this.readonly = false;
  }

  ngOnInit(): void {
    this.renderParts$ = this.answerSubject.pipe(
      switchMap((answer) => this.textService.getRenderParts(this.languageSubject.asObservable(), answer.texts))
    );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.answerSubject.next(this.answer);
    this.languageSubject.next(this.language);
  }

  @HostListener('click')
  onClick(): void {
    if (!this.readonly) {
      this.toggle.emit();
    }
  }
}
