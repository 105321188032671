import { Localization } from '@tstdl/angular';
import { chinese } from './chinese';
import { english } from './english';
import { german } from './german';

export const localizations: Localization[] = [
  english,
  german,
  chinese
];
