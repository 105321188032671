import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { localizationKeys } from 'src/app/localization/localization-keys';

export type SessionMismatchDialogData = {
  testName: string
};

export type SessionMismatchDialogResult = {
  keepCurrent: boolean
};

@Component({
  selector: 'app-session-mismatch-dialog',
  templateUrl: './session-mismatch-dialog.component.html',
  styleUrls: ['./session-mismatch-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionMismatchDialogComponent {
  private readonly dialogRef: MatDialogRef<SessionMismatchDialogComponent>;

  readonly localizationKeys = localizationKeys;
  readonly testName: string;

  constructor(dialogRef: MatDialogRef<SessionMismatchDialogComponent>, @Inject(MAT_DIALOG_DATA) data: SessionMismatchDialogData) {
    this.dialogRef = dialogRef;
    this.testName = data.testName;
  }

  useCurrent(): void {
    this.close({ keepCurrent: true });
  }

  useRemote(): void {
    this.close({ keepCurrent: false });
  }

  private close(result: SessionMismatchDialogResult): void {
    this.dialogRef.close(result);
  }
}
