import { TestRecord } from '../model';
import { apiV1BaseUrl } from './common';
import { EntityLoadParameters, EntityLoadResult, getEntityLoadUrl } from './entity';
import { TestRecordSynchronization, TestRecordSynchronizationResult as TestRecordServiceSynchronizationResult } from '../model/services/test-record';

export const testRecordLoadUrl = getEntityLoadUrl('test-record');
export type TestRecordLoadParameters = EntityLoadParameters;
export type TestRecordLoadResult = EntityLoadResult<TestRecord>;

export const testRecordGetIdUrl = `${apiV1BaseUrl}/test-record/get-id`;
export type TestRecordGetIdParameters = { participantId: string, testId: string };
export type TestRecordGetIdResult = string;

export const testRecordSaveUrl = `${apiV1BaseUrl}/test-record/save`;
export type TestRecordSaveBody = { force: boolean, testRecord: TestRecord };
export type TestRecordSaveResult = { success: true };

export const testRecordSetSubmissionUrl = `${apiV1BaseUrl}/test-record/set-submission`;
export type TestRecordSetSubmissionBody = {
  testRecordId: string,
  submitted: boolean
};
export type TestRecordSetSubmissionResult = { success: true };

export const testRecordSynchronizationUrl = `${apiV1BaseUrl}/test-record/synchronize`;
export type TestRecordSynchronizationBody = TestRecordSynchronization;
export type TestRecordSynchronizationResult = TestRecordServiceSynchronizationResult;
