import { NewEntity } from '@tstdl/base/database';
import { Entity } from './entity';
import { Text, TextWithReference, TextWithReferenceWithoutId } from './text';

export type Feedback = Entity & {
  workshopId: string
};

export type FeedbackQuestion = Entity & {
  workshopId: string,
  feedbackId: string,
  texts: Text[]
};

export type FeedbackRecord = Entity & {
  workshopId: string,
  participantId: string,
  comment: string | null,
  ratings: { [key: string]: number }
};

export type NewFeedback = NewEntity<Feedback>;
export type NewFeedbackQuestion = NewEntity<FeedbackQuestion>;
export type NewFeedbackRecord = NewEntity<FeedbackRecord>;

export type FeedbackWithReference = Feedback & { questions: FeedbackQuestionWithReference[] };
export type FeedbackQuestionWithReference = Omit<FeedbackQuestion, 'texts'> & { texts: TextWithReference[] };

export type NewFeedbackWithReference = Omit<NewFeedback, 'id' | 'workshopId'> & { questions: NewFeedbackQuestionWithReference[] };
export type NewFeedbackQuestionWithReference = Omit<NewFeedbackQuestion, 'id' | 'workshopId' | 'texts' | 'feedbackId'> & { texts: TextWithReferenceWithoutId[] };
