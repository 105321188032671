import { NewEntity } from '@tstdl/base/database';
import { Entity } from './entity';
import { FileWithReference, NewFileWithReference } from './file';

export enum DocumentType {
  Information,
  Condition,
  Consent
}

export type Document = Entity & {
  title: string,
  workshopId: string,
  type: DocumentType,
  files: DocumentFile[]
};

export type DocumentTerm = Entity & {
  workshopId: string,
  documentId: string,
  text: string,
  languageCode: string
};

export type DocumentFile = {
  title: string,
  languageCode: string,
  fileId: string
};

export type NewDocument = NewEntity<Document>;
export type NewDocumentTerm = NewEntity<DocumentTerm>;

export type DocumentWithReference = Omit<Document, 'files'> & { terms: DocumentTermWithReference[], files: DocumentFileWithReference[] };
export type DocumentTermWithReference = DocumentTerm;
export type DocumentFileWithReference = Omit<DocumentFile, 'fileId'> & { file: FileWithReference };

export type NewDocumentWithReference = Omit<NewDocument, 'id' | 'files' | 'workshopId'> & { terms: NewDocumentTermWithReference[], files: NewDocumentFileWithReference[] };
export type NewDocumentTermWithReference = Omit<NewDocumentTerm, 'id' | 'workshopId' | 'documentId'>;
export type NewDocumentFileWithReference = Omit<DocumentFile, 'fileId'> & { file: NewFileWithReference };
