import { CustomError } from '@tstdl/base/error';

export enum ResultTransferErrorReason {
  AgendaMissing
}

export class ResultExportError extends CustomError {
  static readonly errorName = 'ResultExportError';

  reason: ResultTransferErrorReason;

  constructor(reason: ResultTransferErrorReason) {
    super({ message: 'result export failed' });

    this.reason = reason;
  }
}
