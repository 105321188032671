import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppStabilizationService } from '@tstdl/angular';
import { Alphabet, CancellationToken, getRandomString } from '@tstdl/base/utils';
import { BehaviorSubject, combineLatest, firstValueFrom, interval, Observable, of, race, Subject, timer } from 'rxjs';
import { distinctUntilChanged, scan, switchMap, tap, timeInterval, timeout } from 'rxjs/operators';
import { TestRecordGetIdParameters, TestRecordGetIdResult, testRecordGetIdUrl, TestRecordLoadParameters, TestRecordLoadResult, testRecordLoadUrl, TestRecordSetSubmissionBody, TestRecordSetSubmissionResult, testRecordSetSubmissionUrl, TestRecordSynchronizationBody, TestRecordSynchronizationResult, testRecordSynchronizationUrl } from 'src/app/common/api';
import { TestRecord } from 'src/app/common/model';
import { LoadingDialogComponent } from '../../components/loading-dialog/loading-dialog.component';
import { SessionMismatchDialogComponent, SessionMismatchDialogData, SessionMismatchDialogResult } from '../../components/session-mismatch-dialog/session-mismatch-dialog.component';
import { ApiService, CacheType } from '../api.service';
import { SessionService } from '../session.service';
import { UnloadDeferrerService } from '../unload-deferrer.service';

type PendingTestRecord = {
  record: TestRecord,
  force: boolean
};

type TestRecordNotFoundEvent = {
  id: string
};

const session = getRandomString(15, Alphabet.LowerUpperCaseNumbers);

@Injectable({
  providedIn: 'root'
})
export class TestRecordService {
  private readonly appStabilizationService: AppStabilizationService;
  private readonly apiService: ApiService;
  private readonly sessionService: SessionService;
  private readonly unloadDeferrerService: UnloadDeferrerService;
  private readonly dialog: MatDialog;
  private readonly latestRemoteTestRecordMap: Map<string, TestRecord>;
  private readonly pending: Map<string, PendingTestRecord>;
  private readonly pendingCountSubject: Subject<number>;
  private readonly testRecordOverwriteSubject: Subject<TestRecord>;
  private readonly testRecordNotFoundSubject: Subject<TestRecordNotFoundEvent>;
  private readonly pendingAgeSubject: Subject<number>;
  private readonly forceSynchronisationToken: CancellationToken;

  get session(): string {
    return session;
  }

  get pendingCount$(): Observable<number> {
    return this.pendingCountSubject.pipe(distinctUntilChanged());
  }

  get testRecordOverwrite$(): Observable<TestRecord> {
    return this.testRecordOverwriteSubject.asObservable();
  }

  get testRecordNotFound$(): Observable<TestRecordNotFoundEvent> {
    return this.testRecordNotFoundSubject.asObservable();
  }

  get pendingAge$(): Observable<number> {
    return this.pendingAgeSubject.asObservable();
  }

  constructor(appStabilizationService: AppStabilizationService, apiService: ApiService, sessionService: SessionService, unloadDeferrerService: UnloadDeferrerService, dialog: MatDialog) {
    this.appStabilizationService = appStabilizationService;
    this.apiService = apiService;
    this.sessionService = sessionService;
    this.unloadDeferrerService = unloadDeferrerService;
    this.dialog = dialog;

    this.latestRemoteTestRecordMap = new Map();
    this.pending = new Map();
    this.pendingCountSubject = new BehaviorSubject(0);
    this.testRecordOverwriteSubject = new Subject();
    this.testRecordNotFoundSubject = new Subject();
    this.pendingAgeSubject = new BehaviorSubject(0);
    this.forceSynchronisationToken = new CancellationToken();

    this.setUpPendingAgeSubject();
    this.initUnloadDeferrer();

    appStabilizationService.wait().then(() => this.synchronizeLoop());
  }

  private setUpPendingAgeSubject(): void {
    this.appStabilizationService.wait$().pipe(switchMap(() => combineLatest([interval(250), this.pendingCount$]))).pipe(
      timeInterval(),
      scan((age, { value: [, count], interval }) => (count == 0) ? 0 : age + interval, 0),
      distinctUntilChanged()
    ).subscribe(this.pendingAgeSubject);
  }

  private initUnloadDeferrer(): void {
    this.pendingCount$.subscribe((count) => {
      this.unloadDeferrerService.setSync(count > 0);
    });
  }

  load(id: string, skipCache: boolean = false): Observable<TestRecord> {
    const pendingTestRecord = skipCache ? undefined : this.pending.get(id);

    if (pendingTestRecord != undefined) {
      return of(pendingTestRecord.record);
    }

    const latestRemoteTestRecord = skipCache ? undefined : this.latestRemoteTestRecordMap.get(id);

    if (latestRemoteTestRecord != undefined) {
      return of(latestRemoteTestRecord);
    }

    return this.apiService.get<TestRecordLoadParameters, TestRecordLoadResult>(testRecordLoadUrl, { id }).pipe(
      tap((testRecord) => this.latestRemoteTestRecordMap.set(testRecord.id, testRecord))
    );
  }

  getByIds(participantId: string, testId: string): Observable<TestRecord> {
    return this.getId(participantId, testId).pipe(
      switchMap((testRecordId) => this.load(testRecordId))
    );
  }

  getId(participantId: string, testId: string): Observable<string> {
    const parameters: TestRecordGetIdParameters = { participantId, testId };
    return this.apiService.get<TestRecordGetIdParameters, TestRecordGetIdResult>(testRecordGetIdUrl, parameters, CacheType.Full);
  }

  updateTestRecord(id: string, update: Partial<TestRecord>, force: boolean = false): TestRecord {
    const pendingTestRecord = this.pending.get(id);
    const _force = force || (pendingTestRecord != undefined && pendingTestRecord.force);
    const latestTestRecord = pendingTestRecord != undefined ? pendingTestRecord.record : this.latestRemoteTestRecordMap.get(id);

    if (latestTestRecord == undefined) {
      throw new Error('testRecord is undefined');
    }

    const updatedTestRecord: TestRecord = {
      ...latestTestRecord,
      ...update,
      tag: getRandomString(10, Alphabet.LowerUpperCaseNumbers),
      session: this.session
    };

    this.pending.set(id, { record: updatedTestRecord, force: _force });
    this.updatePendingCount();

    this.testRecordOverwriteSubject.next(updatedTestRecord);

    return updatedTestRecord;
  }

  async setSubmissionState(testRecordId: string, submitted: boolean): Promise<TestRecordSetSubmissionResult> {
    return firstValueFrom(this.apiService.post<TestRecordSetSubmissionBody, TestRecordSetSubmissionResult>(testRecordSetSubmissionUrl, { testRecordId, submitted }));
  }

  synchronizeNow(): void {
    this.forceSynchronisationToken.set();
  }

  private async synchronize(): Promise<void> {
    const currentPending = new Map(this.pending.entries());

    const body: TestRecordSynchronizationBody = {
      save: [...this.pending.values()].map(({ record, force }) => ({ record, force })),
      loadIfUpdated: [...this.latestRemoteTestRecordMap.values()].filter((record) => !this.pending.has(record.id)).map(({ id, tag }) => ({ id, tag }))
    };

    if (body.save.length == 0 && body.loadIfUpdated.length == 0) {
      return;
    }

    const result = await firstValueFrom(this.apiService.post<TestRecordSynchronizationBody, TestRecordSynchronizationResult>(testRecordSynchronizationUrl, body)
      .pipe(timeout(15000))
    );

    for (const id of result.saved) {
      const { record } = currentPending.get(id) as PendingTestRecord;
      this.latestRemoteTestRecordMap.set(id, record);

      const { record: latestPendingTestRecord } = this.pending.get(id) as PendingTestRecord;

      if (latestPendingTestRecord == record) {
        this.pending.delete(id);
      }
      else {
        (this.pending.get(id) as PendingTestRecord).force = false;
      }
    }

    for (const id of result.conflict.notFound) {
      this.pending.delete(id);
      this.latestRemoteTestRecordMap.delete(id);
      this.testRecordNotFoundSubject.next({ id });
    }

    for (const record of result.conflict.submitted) {
      this.latestRemoteTestRecordMap.set(record.id, record);
      this.pending.delete(record.id);
      this.testRecordOverwriteSubject.next(record);
    }

    for (const record of result.conflict.sessionMismatch) {
      const workshop = await firstValueFrom(this.sessionService.workshop$);
      const test = (workshop == undefined) ? undefined : workshop.tests.find((test) => test.id == record.testId);
      const testName = (test == undefined) ? 'unknown test name' : test.name;

      const dialogData: SessionMismatchDialogData = {
        testName
      };

      const dialogRef = this.dialog.open(SessionMismatchDialogComponent, { disableClose: true, data: dialogData });

      const { keepCurrent } = await firstValueFrom<SessionMismatchDialogResult>(dialogRef.afterClosed());

      if (keepCurrent) {
        (this.pending.get(record.id) as PendingTestRecord).force = true;
        this.synchronizeNow();
      }
      else {
        const loadingDialog = this.dialog.open(LoadingDialogComponent, { disableClose: true });
        try {
          const latestTestRecord = await firstValueFrom(this.load(record.id, true));
          this.pending.delete(record.id);
          this.latestRemoteTestRecordMap.set(record.id, latestTestRecord);
          this.updateTestRecord(record.id, {}, true);
          this.synchronizeNow();
        }
        finally {
          loadingDialog.close();
        }
      }
    }

    for (const record of result.updated) {
      this.latestRemoteTestRecordMap.set(record.id, record);

      if (!this.pending.has(record.id)) {
        this.testRecordOverwriteSubject.next(record);
      }
    }
  }

  private async synchronizeLoop(): Promise<void> {
    while (true) {
      try {
        this.updatePendingCount();
        await this.synchronize();
        this.updatePendingCount();
      }
      catch (error) {
        console.error(error);
      }

      await firstValueFrom(race(this.forceSynchronisationToken.set$, timer(5000)));
      this.forceSynchronisationToken.unset();
    }
  }

  private updatePendingCount(): void {
    this.pendingCountSubject.next(this.pending.size);
  }
}
