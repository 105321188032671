import { MaybeNewEntity, NewEntity } from '@tstdl/base/database';
import { Entity } from './entity';

export type File = Entity & {
  workshopId: string,
  contentType: string,
  data: ArrayBuffer
};

export type NewFile = NewEntity<File>;
export type MaybeNewFile = MaybeNewEntity<File>;
export type FileWithReference = File;
export type NewFileWithReference = Omit<NewFile, 'id' | 'workshopId'>;
