import { Participant, ParticipantContact, Trainer } from '../model';
import { apiV1BaseUrl } from './common';

export enum TrainerLoginError {
  Unauthorized,
  WorkshopNotFound
}

export enum ParticipantLoginError {
  WorkshopNotFound,
  WorkshopClosed
}

export enum ParticipantRegisterError {
  Unknown,
  AlreadyRegistered
}

export const trainerLoginUrl = `${apiV1BaseUrl}/user/trainer/login`;

export type TrainerLoginParameters = {
  mail: string,
  uniqueNumber: string,
  password: string
};

export type TrainerLoginResult =
  | {
    success: true,
    workshopId: string,
    trainer: Trainer
  }
  | {
    success: false,
    error: TrainerLoginError
  };

export const participantLoginUrl = `${apiV1BaseUrl}/user/participant/login`;

export type ParticipantLoginParameters = {
  mail: string,
  countryCode: string,
  uniqueNumber: string
};

export type ParticipantLoginResult =
  | {
    success: true,
    workshopId: string,
    existing: null | {
      participant: Participant
    }
  }
  | {
    success: false,
    error: ParticipantLoginError
  };

export const participantRegisterUrl = `${apiV1BaseUrl}/user/participant/register`;

export type ParticipantRegisterBody = {
  workshopId: string,
  mail: string,
  contact: ParticipantContact,
  checkedDocumentIds: string[]
};

export type ParticipantRegisterResult = { participant: Participant };

export const participantDeleteUrl = `${apiV1BaseUrl}/user/participant/delete`;

export type ParticipantDeleteParameters = {
  trainerId: string,
  participantId: string
};

export type ParticipantDeleteResult = {
  success: boolean
};
