import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ClozeAnswerWithReference, Language } from 'src/app/common/model';
import { RenderPart, TextService } from 'src/app/modules/core/services/text.service';

@Component({
  selector: 'app-cloze-answer',
  templateUrl: './cloze-answer.component.html',
  styleUrls: ['./cloze-answer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClozeAnswerComponent implements OnInit, OnChanges {
  private readonly textService: TextService;
  private readonly answerSubject: Subject<ClozeAnswerWithReference>;
  private readonly languageSubject: Subject<Language | undefined>;

  @Input() answer: ClozeAnswerWithReference;
  @Input() language: Language | undefined;

  renderParts$: Observable<RenderPart[]>;

  constructor(textService: TextService) {
    this.textService = textService;

    this.answerSubject = new ReplaySubject(1);
    this.languageSubject = new ReplaySubject(1);
  }

  ngOnInit(): void {
    this.renderParts$ = this.answerSubject.pipe(
      switchMap((answer) => this.textService.getRenderParts(this.languageSubject.asObservable(), answer.texts))
    );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.answerSubject.next(this.answer);
    this.languageSubject.next(this.language);
  }
}
